<template>
  <div>
    <div class="uk-card uk-card-default uk-margin-bottom">
      <div class="uk-card-header">
        <h3 class="uk-card-title uk-text-left">
          {{ $t('twitter.user.activity_in_groups') }}
        </h3>
      </div>
      <div
        style="min-height: 449px"
        class="uk-card-body uk-height-max-large"
      >
        <Loader v-if="!loaded" />
        <VueApexCharts
          v-else
          height="100%"
          :series="chatsActivity"
          :options="chartOptions"
          @click="clickHandler"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'
import Loader from '@/components/TGCP/Loader'

export default {
  name: 'WAChannelUserActivityBarGraph',
  components: {
    Loader,
    VueApexCharts
  },
  props: {
    phone: {
      required: true,
      type: String
    }
  },
  data () {
    let self = this
    return {
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          selection: {
            enabled: false
          },
          events: {
            xAxisLabelClick (event, item, { labelIndex }) {
              self.handleLabelClick(labelIndex)
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        xaxis: {
          type: 'numeric',
          title: {
            text: this.$i18n.t('tgcp.user_details.graph_messages')
          },
          labels: {
            formatter: (val) => val > 0 && val < 1 ? '' : val
          },
          forceNiceScale: true,
          min: 0
        },
        dataLabels: {
          enabled: false
        },
        noData: {
          text: this.$t('osint.monitor.no-data'),
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '14px',
            fontFamily: undefined
          }
        },
        tooltip: {
          shared: false,
          marker: {
            show: false
          }
        }
      }
    }
  },
  watch: {
    phone () {
      this.update()
    }
  },
  computed: {
    chatsActivity () {
      const chatsActivity = this.$store.state.whatsapp.users[this.phone]['activity']['groups'] ?? null
      let series = [{
        name: this.$t('twitter.messages.messages-count'),
        data: []
      }]
      if (!chatsActivity) return series
      for (const i in chatsActivity) {
        if (chatsActivity[i].messages_count) {
          series[0].data.push({
            x: this.formUserTitle(this.formUserTitle(chatsActivity[i].subject)),
            y: chatsActivity[i].messages_count,
            id: chatsActivity[i].id
          })
        }
      }
      return series
    },
    loaded () {
      return this.$store.state.whatsapp.isLoaded['user_' + this.phone + '_activity' + '_groups']
    }
  },
  methods: {
    update () {
      const action = 'whatsapp/fetchUserActivityByPhone'
      this.$store.dispatch(action, {
        phone: this.phone,
        type: 'groups'
      })
    },
    clickHandler (event, item, { dataPointIndex }) {
      if (dataPointIndex === -1) return
      const routeData = this.$router.resolve({
        name: 'whatsapp_group_details',
        params: {
          id: String(this.chatsActivity[0]['data'][dataPointIndex].id)
        }
      })
      window.open(routeData.href, '_blank')
    },
    handleLabelClick (labelIndex) {
      if (labelIndex === -1) {
        return
      }

      const routeData = this.$router.resolve({
        name: 'whatsapp_group_details',
        params: {
          id: String(this.chatsActivity[0]['data'][labelIndex].id)
        }
      })

      window.open(routeData.href, '_blank')
    },
    formUserTitle (user) {
      return user
    }
  },
  mounted () {
    this.update()
  }
}
</script>
