<template>
  <div>
    <span
      ref="tooltip"
      class="text-tooltip"
      :class="lightTheme ? 'hover-light' : 'hover-dark'"
      :uk-tooltip="'title: ' + tooltip"
      @click.prevent.stop="copyHandler"
    >
      <span >
        {{ textComputed }}
      </span>
      <br />
    </span>
    <button
      v-if="needShowMore"
      class="uk-button uk-button-text"
      @click="toggle"
    >
      {{ buttonText }}
    </button>
  </div>
</template>

<script>

export default {
  name: 'ShowMore',
  props: {
    text: {
      required: true,
      type: [Number, String, Boolean]
    },
    limit: {
      required: false,
      type: Number,
      default: 200
    },
    lightTheme: {
      reqired: false,
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isOpenned: false,
      copied: false
    }
  },
  computed: {
    tooltip () {
      const text = this.copied ? 'successfully-copied' : 'click-to-copy'
      return this.$t('providers.' + text)
    },
    buttonText () {
      const text = !this.isOpenned ? 'show-more' : 'show-less'
      return this.$t('providers.' + text)
    },
    isValid () {
      return ['string', 'number', 'boolean'].includes(typeof this.text)
    },
    needShowMore () {
      if (!this.isValid) return false
      return String(this.text).length > this.shortText.length
    },
    shortText () {
      return this.isValid ? String(this.text).slice(0, this.limit) : ''
    },
    textComputed () {
      if (!this.isValid) return ''
      if (this.needShowMore) {
        if (!this.isOpenned) return String(this.text).slice(0, this.limit) + '...'
      }
      return this.text
    }
  },
  methods: {
    async copyHandler () {
      clearTimeout(this.copied)
      this.copied = setTimeout(() => {
        // UIkit.tooltip(this.$refs.tooltip).hide()
        this.$refs.tooltip && this.$refs.tooltip.__uikit__.tooltip.hide()
        this.copied = false
        this.$nextTick(this.$forceUpdate)
      }, 2000)
      const { default: copyToClipboard } = await import(/* webpackChunkName: "copyToClipboard" */ '@/utils/copyToClipboard')
      copyToClipboard(this.text)
      // UIkit.tooltip(this.$refs.tooltip).show()
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$nextTick(this.$refs.tooltip.__uikit__.tooltip.show)
        })
      })
    },
    toggle () {
      this.isOpenned = !this.isOpenned
    }
  },
  beforeUnmount () {
    clearTimeout(this.copied)
  }
}
</script>

<style lang="scss">
.text-tooltip {
  cursor: pointer !important;
  transition: 0.3s;
  &:hover {
    transform: scale(1.1);
  }
  &.hover-dark:hover {
    box-shadow: inset 1px 0px 20px 20px rgba(78, 27, 27, 0.76);
    background-color: #ffffff24;
  }
  &:active {
    transform: scale(0.4);
  }
  &.hover-dark:active {
    background-color: green;
  }
}
</style>
