<template>
  <span>
    <span
      class="uk-margin-small-left uk-link"
      @click="loadData"
    >
      <span
        uk-icon="info"
        :uk-tooltip="$t('utils.show-metadata')"
      />
    </span>
    <div
      id="metadata-dialog"
      ref="metadataDialog"
      class="uk-modal-container"
      uk-modal
    >
      <div class="uk-modal-dialog">
        <div class="uk-modal-body">
          <h3
            class="uk-text-center"
          >
            {{ $t('utils.file-metadata') }}
          </h3>
          <div
            v-if="fileStatus === 'LOADING'"
            class="uk-text-center"
          >
            <Loader />
          </div>
          <div
            v-else-if="fileStatus === 'NOT_FOUND' || fileMetadata === null"
          >
            {{ $t('utils.metadata-not-found') }}
          </div>
          <div 
            v-if="fileLocation"
          >
            <LMap
              :style="`height:250px; width:100%`"
              :zoom="15"
              :center="[fileLocation.latitude, fileLocation.longitude]"
            >
              <LTileLayer
                :url="tileUrl"
                :noWrap="true"
              />
              <LMarker :lat-lng="[fileLocation.latitude, fileLocation.longitude]" />
            </LMap>
          </div>
          <div
            v-if="fileStatus === 'LOADED' && fileMetadata"
            :class="fileLocation ? 'uk-margin-small-top map-height' : 'full-height'"
            style="overflow-y: auto;"
          >
            <table class="uk-table uk-table-divider uk-table-striped uk-table-left">
              <thead>
                <th class="uk-table-shrink uk-text-left">
                  {{ $t('discord.guild_details.parameter') }}
                </th>
                <th class="uk-table-expand">
                  {{ $t('discord.guild_details.value') }}
                </th>
              </thead>
              <tbody>
                <tr
                  v-for="(value, key) in fileMetadata"
                  :key="key+value+'_meta'"
                >
                  <td class="uk-text-left uk-text-nowrap">
                    {{ key }}
                  </td>
                  <td class="uk-text-left">
                    <template v-if="(typeof value !== 'object')">
                      <ShowMore
                        :text="value"
                        :limit="150"
                        :light-theme="true"
                      />
                    </template>
                    <template v-else-if="(Array.isArray(value))">
                      <ShowMore
                        :text="value.join(', ')"
                        :limit="150"
                        :light-theme="true"
                      />
                    </template>
                    <template v-else>
                      <RawRecurse 
                        :raw="value"
                      />
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="uk-modal-footer uk-text-center">
          <button
            class="uk-button uk-button-default uk-margin-right uk-modal-close"
            type="button"
          >
            {{ $t('links.cancel') }}
          </button>
          <button
            v-if="fileMetadata"
            class="uk-button uk-button-default"
            type="button"
            @click="JSONToFile"
          >
            {{ $t('utils.save-json') }}
          </button>
          <a
            class="uk-button uk-button-default uk-margin-left"
            :href="keepLink ? link : (gateParse + linkParsed)"
          >
            {{ $t('utils.download') }}
          </a>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import UIkit from 'uikit'
import config from '@/config'
import gateways from '@/config/gateways'
import { mapActions, mapGetters } from 'vuex'
import Loader from '@/components/TGCP/Loader'
import RawRecurse from '@/components/providers/rawDetailsRecursiveRender'
import ShowMore from '@/components/providers/showMore'
import { LMap, LMarker, LTileLayer } from 'vue2-leaflet'

export default {
  name: 'MetadataExport',
  components: {
    Loader,
    RawRecurse,
    ShowMore,
    LMarker,
    LMap,
    LTileLayer
  },
  props: {
    link: {
      required: true,
      type: String
    }
  },
  computed: {
    ...mapGetters('utils', ['metadata']),
    gateParse () {
      let domain = window.location.hostname
      return gateways[domain]
        ? gateways[domain]
        : gateways['default']
    },
    keepLink () {
      return this.link.startsWith(this.gateParse) || this.link.startsWith('https://')
    },
    tileUrl () {
      return config.servers.map + '/{z}/{x}/{y}.png'
    },
    linkParsed () {
      if (!this.link) return ''
      if (this.link.startsWith(this.gateParse)) {
        return this.link.replace(this.gateParse, '')
      } else {
        return this.link.startsWith('/') ? this.link.replace('/', '') : this.link
      }
    },
    fileStatus () {
      if (!this.linkParsed || !this.metadata[this.linkParsed]) return null
      return (this.metadata[this.linkParsed].STATUS)
    },
    fileMetadata () {
      if (!this.fileStatus || this.fileStatus !== 'LOADED' || !this.metadata[this.linkParsed].metadata) return null
      return this.metadata[this.linkParsed].metadata
    },
    fileLocation () {
      if (!this.fileStatus || this.fileStatus !== 'LOADED' || !this.metadata[this.linkParsed].location) return null
      return this.metadata[this.linkParsed].location
    }
  },
  methods: {
    ...mapActions('utils', ['fetchMetadata']),
    async loadData () {
      this.showModal()
      const data = await this.fetchMetadata({ link: this.linkParsed })
    },
    showModal () {
      const dialog = this.$refs.metadataDialog
      if (dialog) {
        UIkit.modal(dialog).show()
      } else {
        console.error('Can\'t find dialog')
      }
    },
    closeModal () {
      const dialog = this.$refs.metadataDialog
      if (dialog) {
        UIkit.modal(dialog).hide()
      }
    },
    JSONToFile () {
      if (!this.linkParsed || !this.fileMetadata) return
      const fileName = this.linkParsed.split('/').slice(-1)[0]
      const blob = new Blob([JSON.stringify(this.fileMetadata, null, 2)], {
        type: 'application/json'
      })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `${fileName}.json`
      a.click()
      URL.revokeObjectURL(url)
    }
  }
}
</script>

<style lang="scss" scoped>
.map-height {
  max-height: calc(100vh - 100px - 60px - 40px - 250px - 80px - 20px);
}
.full-height {
  max-height: calc(100vh - 100px - 60px - 40px - 80px);
}
</style>
