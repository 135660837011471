<template>
  <div class="home uk-container">
    <div class="uk-margin">
      <ProfileBulkCreate
        v-if="tag && users.length"
        :tag="tag"
        :search="tag"
      />
      <div
        class="uk-display-inline-block uk-margin-small-bottom uk-width-expand"
        uk-grid
      >
        <form
          class="uk-grid-small uk-form-small uk-display-inline-block"
          uk-grid
          @submit.prevent="onSubmit"
        >
          <div 
            class="uk-flex uk-flex-wrap" 
            style="place-content: center;"
          >
            <EntityTip
              namespace="tgcp"
              type="User"
            >
              <template #input>
                <input
                  v-model.trim="search"
                  type="text"
                  :placeholder="$t('tgcp.channels.search_placeholder')"
                  class="uk-input uk-form-width-small uk-form-width-medium"
                  @keydown.enter.prevent="onSubmit"
                />
              </template>
            </EntityTip>
            <div class="uk-margin-medium-bottom uk-hidden@m uk-hidden@m uk-hidden@l uk-hidden@xl"/>
            <vueSelect
              v-model="currentOrderBy"
              :options="orderListTranslated"
              label="label"
              class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
            />
            <vueSelect
              v-model="currentCountry"
              :options="countriesComputed"
              label="label"
              class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
            />
            <label 
              class="uk-display-inline-block uk-margin-left uk-margin-right" 
              style="align-self: center;"
            >
              <input
                v-model="full_match"
                class="uk-checkbox"
                type="checkbox"
                @change="fullEntry"
              />
              {{ $t('tgcp.users.full_match') }}
            </label>
            <button
              class="uk-button uk-button-primary uk-search-icon"
              @click.prevent="onSubmit"
            >
              <span uk-search-icon/>
            </button>
          </div>
          <SearchInDbHint />
          <div
            class="uk-display-inline-block"
            style="padding-left: 50px;"
            uk-grid
          >
            <div class="uk-form-small uk-display-inline-block">
              <label>
                <input
                  v-model="search_fields"
                  class="uk-checkbox"
                  value="first_name"
                  type="checkbox"
                />
                {{ $t('tgcp.users.first_name') }}
              </label>
            </div>
            <div class="uk-form-small uk-display-inline-block">
              <label>
                <input
                  v-model="search_fields"
                  class="uk-checkbox"
                  value="last_name"
                  type="checkbox"
                />
                {{ $t('tgcp.users.last_name') }}
              </label>
            </div>
            <div class="uk-form-small uk-display-inline-block">
              <label>
                <input
                  v-model="search_fields"
                  class="uk-checkbox"
                  value="username"
                  type="checkbox"
                />
                {{ $t('tgcp.users.username') }}
              </label>
            </div>
            <div class="uk-form-small uk-display-inline-block">
              <label>
                <input
                  v-model="search_fields"
                  class="uk-checkbox"
                  value="bio"
                  type="checkbox"
                />
                {{ $t('tgcp.users.bio') }}
              </label>
            </div>
            <div class="uk-form-small uk-display-inline-block">
              <label>
                <input
                  v-model="search_fields"
                  class="uk-checkbox"
                  value="historical_usernames"
                  type="checkbox"
                />
                {{ $t('tgcp.users.historical-usernames') }}
              </label>
            </div>
            <div class="uk-form-small uk-display-inline-block">
              <label>
                <input
                  v-model="search_fields"
                  class="uk-checkbox"
                  value="historical_first_names"
                  type="checkbox"
                />
                {{ $t('tgcp.users.historical-first-names') }}
              </label>
            </div>
            <div class="uk-form-small uk-display-inline-block">
              <label>
                <input
                  v-model="search_fields"
                  class="uk-checkbox"
                  value="historical_last_names"
                  type="checkbox"
                />
                {{ $t('tgcp.users.historical-last-names') }}
              </label>
            </div>
            <div class="uk-form-small uk-display-inline-block">
              <label>
                <input
                  v-model="search_fields"
                  class="uk-checkbox"
                  value="historical_bio"
                  type="checkbox"
                />
                {{ $t('tgcp.users.historical-bio') }}
              </label>
            </div>
          </div>
        </form>
      </div>

      <div v-if="!isLoaded">
        <Loader />
      </div>

      <div v-else>
        <Items
          v-for="user in users"
          :key="user"
          :user="user"
        />
        <NotFound v-if="isLoaded && !hasDataToView" />
        <div
          v-if="!hidePagination"
          class="uk-margin"
        >
          <ul class="uk-pagination uk-flex-center">
            <li :class="+currentPage > 1 ? 'uk-enabled' : 'uk-disabled'">
              <a
                class="uk-position-center"
                @click="prevPage"
              >
                <span uk-pagination-previous/>
              </a>
            </li>
            <li
              class="uk-padding-remove"
            >
              <input
                v-model="goToPage"
                class="uk-input uk-text-center uk-form-blank uk-text-truncate"
                style="max-width: 250px; width: 40px"
                :style="`width: ${(('' + goToPage).length + 2) * 14 }px`"
                @change="jumpToPage"
                @focus="$event.target.select()"
              />
            </li>
            <li :class="isNextPage ? 'uk-enabled' : 'uk-disabled'">
              <a
                class="uk-position-center"
                @click="nextPage"
              >
                <span uk-pagination-next/>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <ExportDialog
      v-if="hasExport"
      :id="id"
      namespace="tgcp"
      type="users"
      subtype="all"
      :search="search"
      :query="{
        search,
        order_type,
        country_code: this.selectedCountry,
        full_match,
        search_fields
      }"
      :totalCount="0"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { countryCodeToName } from '@/filters/globalFilters'
import { defineAsyncComponent } from 'vue'
import vueSelect from '@/components/VueSelect'

export default {
  name: 'UserList',
  components: {
    EntityTip: defineAsyncComponent(() => import('@/components/EntityTip/Tip.vue')),
    Items: defineAsyncComponent(() => import('@/components/TGCP/UserList/Items.vue')),
    ProfileBulkCreate: defineAsyncComponent(() => import('@/components/cases/Profile/BulkCreate')),
    vueSelect,
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    Loader: defineAsyncComponent(() => import('@/components/TGCP/Loader')),
    SearchInDbHint: defineAsyncComponent(() => import('@/components/general/SearchInDbHint'))
  },
  data () {
    return {
      users: [],
      order_by: this.$route.query.order_by || 'id',
      order_type: this.$route.query.order_type || 'asc',
      search: this.$route.query.search || '',
      search_fields: [],
      full_match: this.$route.query.full_match || false,
      selectedCountry: this.$route.query.country_code || '',
      countries: [],
      goToPage: 1
    }
  },
  watch: {
    goToPage (newVal, oldVal) {
      if (!/^[0-9]*$/.test(newVal) || !+newVal) {
        this.goToPage = oldVal
      }
    },
    currentPage (newVal) {
      this.goToPage = newVal
    },
    search: {
      immediate: false,
      handler (value) {
        globalThis.eventBus.$emit('EntityTip.id', value)
      }
    }
  },
  computed: {
    ...mapGetters('tgcp', [
      'currentPage',
      'allListUsers',
      'isNextPage',
      'limit'
    ]),
    hasExport () {
      return (this?.$route?.path === '/tgcp/user') ? (this.search && this.users.length > 0) : this.users.length > 0
    },
    id () {
      return this?.$route?.params?.id
    },
    currentOrderBy: {
      get () {
        return this.order
      },
      set (obj) {
        this.order = obj && obj.value
        this.setOrder(obj.value)
      }
    },
    currentCountry: {
      get () {
        return this.selectedCountry
      },
      set (obj) {
        this.selectedCountry = obj ? obj.value : ''
        this.selectCountry()
      }
    },
    orderListTranslated () {
      return [
        { value: 'asc_id', label: this.$t('tgcp.users.sort_by_id_asc') },
        { value: 'desc_id', label: this.$t('tgcp.users.sort_by_id_desc') }
      ]
    },
    countriesComputed () {
      const countries = this.countries.map(({ code }) => ({
        label: this.countryCodeToName(code),
        value: code
      })).sort((previous, next) => {
        const previousLabel = previous?.label
        const nextLabel = next?.label
        if (previousLabel < nextLabel) {
          return -1
        }
        if (previousLabel > nextLabel) {
          return 1
        }

        return 0
      })
      return [{ value: '', label: this.$t('tgcp.filters.select_country') }, ...countries]
    },
    tag () {
      return this.$route.query.search
    },
    isLoaded () {
      return this.$store.state.tgcp.isLoaded['users']
    },
    hasDataToView () {
      return this.users.length > 0
    },
    hidePagination () {
      if (this.currentPage === 1 && this.isNextPage === false) return true
      else return false
    },
    order: {
      get () {
        return this.order_type + '_' + (this.order_by === 'tg_id' ? 'id' : this.order_by)
      },
      set (value) {
        if (value) [this.order_type, this.order_by] = value.split('_')
      }
    },
    escapeSearch () {
      if (this.search) {
        let search = this.search
        return search[0] === '@' ? search.slice(1) : search
      }
      return this.search
    }
  },
  methods: {
    ...mapMutations('tgcp', ['clearQueryString']),
    ...mapActions('tgcp', [
      'fetchAllUsers',
      'setPage',
      'addParamToQuery',
      'clearUsersToView',
      'fetchUserListCountries'
    ]),
    countryCodeToName,
    async nextPage () {
      const offset = (this.limit - 1) * this.currentPage
      await this.addParamToQuery({ offset }) 
      await this.setPage(this.currentPage + 1) 
      await this.update()
    },
    async prevPage () {
      const limit = this.limit - 1
      const offset = limit * this.currentPage - (limit * 2)
      await this.addParamToQuery({ offset }) 
      await this.setPage(this.currentPage - 1) 
      await this.update()
    },
    async update (query = {}) {
      let response = await this.fetchAllUsers(query)
      if (!response || response.length === 0) this.users = []
      response = response.map(user => user.tg_id)
      this.users = [...response]
      if (response.length === this.$store.state.tgcp.queryParams.limit) this.users = this.users.slice(0,-1)
    },
    async jumpToPage ({ target }) {
      const { query } = this.$route
      let newOffset = { ...query, limit: 21 }
      for (let i = 10; i > 0; i--) {
        newOffset.offset = (20) * (--target.value)
        await this.update(newOffset)
        if (this.users.length) {
          this.goToPage = +target.value + 1
          this.setPage(+target.value + 1)
          await this.addParamToQuery({ offset: newOffset.offset })
          return
        }
      }
      await this.update()
      this.goToPage = this.currentPage
      this.$notify({ group: 'general', title: `Page not found`, type: 'error' })
    },
    async selectCountry () {
      this.setPage(1) 
      await this.addParamToQuery({ country_code: this.selectedCountry, offset: 0 }) 
      await this.clearUsersToView() 
      await this.update()
    },
    async onSubmit () {
      this.setPage(1)
      await this.addParamToQuery({ offset: 0, search: this.escapeSearch, search_fields: this.search_fields }) 
      await this.clearUsersToView() 
      await this.update()
    },
    async setOrder (order) {
      this.order = order
      await this.addParamToQuery({ order_by: this.order_by.replace('id', 'tg_id'), order_type: this.order_type }) 
      await this.clearUsersToView() 
      await this.update()
    },
    fullEntry () {
      event.target.checked === true ? this.addParamToQuery({ full_match: true }) : this.addParamToQuery({ full_match: false })
    }
  },
  async created () {
    this.countries = await this.fetchUserListCountries()
    let { offset } = this.$route.query
    const page = Math.floor(offset / (this.limit - 1) + 1)
    this.goToPage = page || 1
    this.setPage(page || 1)
    offset = (this.limit - 1) * page - (this.limit - 1)
    if (offset) this.addParamToQuery({ offset })
    this.clearQueryString()
    this.update()
  },
  mounted () {
    const searchFields = this.$route.query.search_fields
    if (!searchFields) return
    this.search_fields = Array.isArray(searchFields) ? searchFields : [searchFields]
  },
  beforeUnmount () {
    this.clearUsersToView()
  }
}
</script>
