<template>
  <div>
    <div
      v-if="avatars.length"
      ref="slider"
      uk-slider="finite: true"
      class="uk-position-relative uk-visible-toggle uk-padding-small"
    >
      <ul
        class="uk-slider-items uk-child-width-1-1 uk-child-width-1-1@s uk-child-width-1-1@m"
      >
        <li
          v-for="(image, i) in localAvatars"
          :key="image.avatar_cdn_path"
          @click="showLightBox($event, i)"
        >
          <GeneralAvatar
            :src="url(image.avatar_cdn_path)"
          />
        </li>
      </ul>
      <div uk-grid>
        <button
          class="uk-position-small uk-position-bottom-left"
          uk-slidenav-previous
          uk-slider-item="previous"
        />
        <button
          class="uk-position-small uk-position-bottom-right"
          uk-slidenav-next
          uk-slider-item="next"
        />
      </div>
    </div>
    <Avatar
      v-else
      :avatar="data"
    />
  </div>
</template>

<script>
import UIkit from 'uikit'
import gateways from '@/config/gateways'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'AvatarList',
  components: {
    Avatar: defineAsyncComponent(() => import('@/components/Twitter/Avatar')),
    GeneralAvatar: defineAsyncComponent(() => import('@/components/GeneralAvatar'))
  },
  props: {
    data: {
      type: Object
    }
  },
  data () {
    return {
      currentIndex: 0,
      index: 0
    }
  },
  computed: {
    avatars () {
      return this.data.avatars
    },
    localAvatars () {
      if (this.avatars === void 0) {
        return []
      }
      if (this.long) {
        return [...this.avatars].slice(this.index, this.index + 3)
      }

      return this.avatars.filter(avatar => avatar.avatar_cdn_path)
    },
    long () {
      return this.avatars.length > 20
    },
    sources () {
      if (this.avatars === void 0) {
        return []
      }

      return this.avatars.map(item => {
        return { source: this.url(item.avatar_cdn_path), type: 'image' }
      })
    },
    gateway () {
      return gateways.default.substring(0, gateways.default.length - 1)
    }
  },
  methods: {
    url (url) {
      try {
        if (!url.includes('/twitter/file/')) {
          return this.gateway + '/twitter/file/' + url
        } else {
          return this.gateway + '/' + url
        }
      } catch (error) {
        console.debug(error)
      }
      return null
    },
    count (arr) {
      return arr.length
    },
    next () {
      if (!this.long) return
      if (+this.currentIndex === 0) {
        this.currentIndex = 1
        setTimeout(UIkit.slider(this.$refs.slider).show, 1, 1)
      } else if (+this.index === this.avatars.length - 3) {
        this.currentIndex = 2
        setTimeout(UIkit.slider(this.$refs.slider).show, 1, 2)
      } else if (this.currentIndex === 1) {
        this.index = this.index + 1
      }
    },
    prev () {
      if (!this.long) return
      if (this.currentIndex === 2) {
        this.currentIndex = 1
        setTimeout(UIkit.slider(this.$refs.slider).show, 1, 1)
      } else if (+this.index === 0) {
        this.currentIndex = 0
        setTimeout(UIkit.slider(this.$refs.slider).show, 1, 0)
      } else {
        this.index = this.index - 1
      }
    },
    showLightBox (event, localIndex) {
      event.preventDefault()
      UIkit
        .lightboxPanel({
          items: this.sources
        })
        .show(this.index + localIndex)
    }
  }
}
</script>
