import config from '@/config'
import { apiRequest } from '@/utils/api'

export default {
  namespaced: true,
  state: {
    translatedCache: new Map(),
    metaLoaded: {}
  },
  getters: {
    metadata: (state) => state.metaLoaded
  },
  mutations : {
    setTranslatedCache (state, payload) {
      state.translatedCache[payload.key] = payload.data
    },
    setMetaState (state, payload) {
      state.metaLoaded[payload.key] = payload.data
    }
  },
  actions: {
    translateText: async ({ state, commit, rootState }, { text }) => {
      const translateTo = rootState.settings.translate
      try {
        const translatedCacheKey = JSON.stringify({
          translateTo,
          text
        })
        const hasTranslatedCache = state.translatedCache.has(translatedCacheKey)
        if (!hasTranslatedCache) {
          const {
            data: { translation }
          } = await apiRequest(`/translate`, 'POST', {}, config.servers.lang_server, {
            lang: translateTo,
            text: text
          })
          commit('setTranslatedCache', { key: translatedCacheKey, data: { data: { translation } }})
          return {
            data: {
              translation
            }
          }
        }
        return state.translatedCache[translatedCacheKey]
      } catch (error) {
        console.debug('[Translate/catch]', error)
      }
    },
    async fetchMetadata ({ state, commit }, { link }) {
      if (link in state.metaLoaded && state.metaLoaded[link].STATUS === 'LOADED') {
        return state.metaLoaded[link]
      }
      commit('setMetaState', { key: link, data: { STATUS: 'LOADING' } })
      try {
        const { data } = await apiRequest(`/meta/get?file=${link}`, 'GET', {}, config.servers.tools)
        commit('setMetaState', { key: link, data: { ...data, STATUS: 'LOADED' } })
        return data
      }
      catch (error) {
        commit('setMetaState', { key: link, data: { STATUS: 'NOT_FOUND' } })
      }
    }
  }
}
