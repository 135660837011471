import Base from '../../Base.js'
import Schemas from '../../../Schemas'

class TgcpChannelsUser extends Base {
  schema = Schemas.tgcp.channel
  OPTIONS_SCHEMA = ['id', 'query', 'allowedFields', 'localizeFields', 'parentProps']
  fetch = async ({ dispatch, options }) => {
    const {
      parentProps: {
        participantType,
        participantStatus
      }, id, query, channels } = options
    if (!!channels && channels.length) {
      return channels
    } else {
      let localQuery = {
        ...query,
        offset: 0,
        limit: 100
      }
      let params = {
        id,
        query: localQuery,
        participantType,
        participantStatus,
        withoutFetchingToView: true
      }
      let entries = []
      let entry = Array(1)
      while (entry.length && localQuery.offset < 5000) {
        entry = await dispatch('fetchChannelsByUserId', params)
        for (const index in entry) {
          try {
            const data = await dispatch('getStats', {
              id: entry[index].tg_id,
              type: 'chats'
            })
            entry[index] = {
              ...entry[index],
              ...data
            }
          } catch (error) {
            console.debug(error)
          }
        }
        entries = entries.concat(entry)
        localQuery.offset += localQuery.limit
      }
      return entries
    }
  }
}

export default TgcpChannelsUser
