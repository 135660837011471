import router from '@/router'
import config from '@/config'
import i18n from '@/i18n'
import unicode from '@/utils/unicode'
import { apiRequest, getQueryString } from '@/utils/api'
import { notify } from '@kyvg/vue3-notification'
import {
  filteredArr,
  filterFields,
  messageMarkup,
  localizeCSVFields,
  addMainMessageField,
  removeEscapeCharacters,
  dayjsCurrentTimestamp
} from '@/utils/csvExport/fieldsParse'
import categories from '@/utils/categories.json'
import TgcpJoinLink from '@/utils/tgcpJoinLink'
const $t = str => i18n.global.t(str)

const sanitize = require('sanitize-filename')

const flatternObj = obj => {
  const flattened = {}
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(flattened, flatternObj(obj[key]))
    } else {
      flattened[key] = obj[key]
    }
  })

  return flattened
}
function reflect (promise) {
  return promise.then(
    v => {
      return { status: 'fulfilled', value: v }
    },
    error => {
      return { status: 'rejected', reason: error }
    }
  )
}

function fetchChannelById ({ commit, state, dispatch, rootState }, id) {
  commit('startLoad', 'channel_' + id)
  commit('startLoad', 'channel_' + id + '_stats')
  return new Promise((resolve, reject) => {
    apiRequest('/chats/' + id, 'GET')
    .then(data => {
      var userData = data.data
      userData.activity = {}
      userData.audience = {}
      if (userData.avatar && userData.avatar.file_location) userData.avatar.file_location = config.servers.tgcp_static + userData.avatar.file_location
      userData.answer_status = 'FOUND'
      commit('addChannelData', userData)
      resolve(userData)
    })
    .catch(err => {
      console.debug(err)
      if (err.response.status === 404) {
        commit('addChannelData', { tg_id: id, answer_status: 'NOT_FOUND' })
      }
      reject(err)
    })
    .finally(() => {
      commit('stopLoad', 'channel_' + id)
    })
  })
}

async function fetchUserById ({ commit, dispatch }, params = {}) {
  let payload = params
  // Fallback for old code
  if (typeof params !== 'object') {
    payload = {}
    payload.id = params
    payload.requestStats = true
  }

  const { id, requestStats } = payload

  if (!id) {
    return
  }

  commit('startLoad', 'user_' + id)

  try {
    const { data } = await apiRequest('/users/' + id, 'GET')

    data.activity = {}

    if (data.avatar && data.avatar.file_location) {
      data.avatar.file_location =
      config.servers.tgcp_static + data.avatar.file_location
    }

    data.answer_status = 'FOUND'

    commit('addUserData', data)

    if (requestStats) {
      await dispatch('fetchUserStatsById', id)
    }

    return data
  } catch (error) {
    if (error.isAxiosError && error.response.status === 404) {
      const notFoundUser = {
        tg_id: id,
        answer_status: 'NOT_FOUND'
      }

      try {
        const phoneExists = await dispatch('checkPhoneExists', id)

        if (phoneExists.data) {
          const user = {
            ...notFoundUser,
            has_phone: true
          }

          commit('addUserData', user)

          return user
        } else {
          commit('addUserData', { tg_id: id, answer_status: 'NOT_FOUND' })
        }
      } catch (_error) {
        commit('addUserData', { tg_id: id, answer_status: 'NOT_FOUND' })
      }

      return notFoundUser
    }

    throw error
  } finally {
    commit('stopLoad', 'user_' + id)
  }
}

export default {
  namespaced: true,
  state: {
    users: {},
    listUsers: {},
    pagination: {
      currentPage: 1,
      nextPage: false
    },
    channels: {},
    channelAvatars: {},
    channelTopics: {},
    userAvatars: {},
    listChannels: {},
    listPastChannels: {},
    messages: {},
    listmediamessages: {},
    queryParams: {  
      limit: 21
    },
    defaultParams: {
      channellist_limit: 21,
      channellist_mini_limit: 7
    },
    stats: {},
    parsedStats: [],
    searchResults: [],
    isLoaded: {
      // NOTE: Почему taskAdd по умолчанию true?
      taskAdd: true
    },
    listMessages: {},
    listAdmins: {},
    tasks: {},
    chatLanguages: {
      time: null,
      data: null
    },
    listTasks: {},
    csv: {
      ready: 0,
      autostart: false,
      data: {},
      format: 'csv',
      filename: ''
    },
    geoTasks: [],
    geoClusters: {
      limit: 0,
      items: []
    },
    translatedCache: new Map(),
    knownLinks: [],
    relations: {
      linked_from: [],
      links_to: []
    }
  },
  getters: {
    relations: state => state.relations,
    linkedFrom: state => state?.relations?.['linked_from'],
    linksTo: state => state?.relations?.['links_to'],
    allChannels: state => state.channels,
    allChannelTopics: state => state.channelTopics,
    allListChannels: state => state.listChannels,
    allListAdmins: state => state.listAdmins,
    allListMediaMessages: state => state.listmediamessages,
    allListMessages: state => state.listMessages,
    allListPastChannels: state => state.listPastChannels,
    allListTasks: state => state.listTasks,
    allListUsers: state => state.listUsers,
    allMessages: state => state.messages,
    allTasks: state => state.tasks,
    allUsers: state => state.users,
    channelAvatars: state => state.channelAvatars,
    csvAutoDownload: state => state.csv.autostart,
    csvData: state => state.csv.data,
    csvFrom: state => state.csv.from,
    csvReady: state => state.csv.ready,
    csvType: state => state.csv.type,
    csvFormat: state => state.csv.format,
    csvName: state => state.csv.filename,
    csvAllowedFields: state => state.csv.allowedFields,
    currentPage: state => {
      return state.pagination.currentPage
    },
    errors: state => state.errors,
    geoTasks: state => state.geoTasks,
    isLoaded: state => state.isLoaded,
    isNextPage: state => state.pagination.nextPage,
    limit: state => state.queryParams.limit,
    parsedStats: state => state.parsedStats,
    stats: state => state.stats,
    userAvatars: state => state.userAvatars,
    userStats: state => id => {
      return state.users[id].stats
    },
    usersToView: state => ids => {
      return ids.map(id => state.users[id])
    },
    geoClusters: state => state.geoClusters.items,
    geoClustersLimit: state => state.geoClusters.limit,
    knownLinks: state => state.knownLinks
  },
  mutations: {
    startLoad: (state, payload) => {
      state.isLoaded[payload] = false
    },
    stopLoad: (state, payload) => {
      state.isLoaded[payload] = true
    },
    addUserData: (state, payload) => {
      if (!payload.tg_id) return
      state.users[payload.tg_id] = payload
    },
    addMessageData: (state, payload) => {
      if (!payload.tg_id) return
      state.messages[payload.tg_id] = payload
    },
    addUserDataChatsActivity (state, payload) {
      if (!payload.tg_id) return
      state.users[payload.tg_id].chats_activity = payload
    },
    addUserAvatar (state, payload) {
      if (!payload.tg_id) return
      state.userAvatars[payload.tg_id] = Object.freeze(payload)
    },
    fetchMessageLink (state, payload) {
      if (!payload.message_id) return
      state.messages[payload.message_id].pagination = payload
    },
    fetchMediaMessageLink (state, payload) {
      if (!payload.message_id) return
      state.mediamessages[payload.message_id].pagination = payload
    },
    addUserDataGeo (state, payload) {
      if (!payload.tg_id) return
      state.users[payload.tg_id].geo = payload
    },
    fetchUserGeoNear (state, payload) {
      if (!payload.tg_id) return
      state.users[payload.id].near = payload.geolocation
    },
    addChannelDataGeo (state, payload) {
      if (!payload.tg_id) return
      state.channels[payload.tg_id].geo = payload
    },
    addUserDataActivity (state, payload) {
      if (!payload.tg_id) return
      state.users[payload.tg_id]['activity'][payload.key] = payload
    },
    addChatForwardsActivity (state, payload) {
      if (!payload.tg_id) return
      state.channels[payload.tg_id]['activity'].forwards = payload
    },
    addChatDataUserActivity (state, payload) {
      if (!payload.tg_id) return
      state.channels[payload.tg_id]['activity'].users = payload
    },
    addChatDataUserGeo (state, payload) {
      if (!payload.id) return
      state.channels[payload.id]['activity'].audience_countries = payload.audience_countries
    },
    addUserDataStats (state, payload) {
      if (!payload.tg_id) return
      state.users[payload.tg_id].stats = payload
    },
    addUserReplies (state, { tgId, data }) {
      state.users[tgId].replies = data
    },
    userHavePhone (state, id) {
      state.users[id].has_phone = true
    },
    userPhone: (state, payload) => {
      if (!payload.tg_id) return
      state.users[payload.tg_id].phone = payload.phone
    },
    addChannelData: (state, payload) => {
      if (!payload.tg_id) return
      state.channels[payload.tg_id] = payload
    },
    addChannelTopic: (state, payload) => {
      if (!payload.tg_id) return
      state.channelTopics[payload.tg_id] = payload.topics
    },
    addChannelAvatar (state, payload) {
      if (!payload.tg_id) return
      if (state.channels[payload.tg_id]?.avatar && Array.isArray(payload.data) && payload.data.length > 1) {
        const mainAvatar = state.channels[payload.tg_id].avatar
        const indexProvided = payload.data.findIndex(item => item.file_location === mainAvatar.file_location)
        if (indexProvided !== 0) {
          payload.data.splice(indexProvided, 1)
          payload.data.unshift(mainAvatar)
        }
      }
      state.channelAvatars[payload.tg_id] = payload.data
    },
    addChannelWordsStatById (state, { payload, id }) {
      if (!id) return
      state.channels[id].words = payload
    },
    addChannelDataActivity (state, payload) {
      if (!payload.tg_id) return
      state.channels[payload.tg_id]['activity'][payload.key] = payload
    },
    addChannelDataAudience (state, payload) {
      if (!payload.tg_id) return
      state.channels[payload.tg_id]['audience'][payload.key] = payload
    },
    addChannelDataStats (state, payload) {
      if (!payload.tg_id) return
      state.channels[payload.tg_id].stats = payload
    },
    addEntityDataStats (state, payload) {
      if (!payload.tg_id) return
      state.channels[payload.tg_id].entity_data = payload
    },
    addUserEntityDataStats (state, payload) {
      if (!payload.tg_id) return
      state.users[payload.tg_id].entity_data = payload
    },
    fetchAllUsers (state, users) {
      users.map(user => {
        if (!state.users[user.tg_id]) state.users[user.tg_id] = user
      })
    },
    editTask (state, task) {
      state.tasks[task.id] = task
    },
    fetchAllTasks (state, tasks) {
      tasks.map(task => {
        if (!state.tasks[task.id]) state.tasks[task.id] = task
      })
    },
    fetchTask (state, tasks) {
      state.tasks = tasks
    },
    fetchTaskChats (state, { chats, id }) {
      if (!chats) return
      state.tasks[id].chats = chats
    },
    listUsers (state, users) {
      const page = ++Object.keys(state.listUsers).length
      const usersId = users.map(user => user.tg_id)
      state.listUsers[page] = usersId
    },
    addHiddenParamToQuery: (state, param) => Object.assign(state.queryParams, param),
    setPage: (state, page) => {
      state.pagination.currentPage = page
    },
    setPageWithType: (state, page) => {
      state.pagination[page.type] = page.page
    },
    fetchUsersToView: (state, { usersId, page }) => {
      if (usersId.length === state.queryParams.limit) state.pagination.nextPage = true
      else state.pagination.nextPage = false
      if (page in state.listUsers) delete state.listUsers[page]
      state.listUsers[page] = [...usersId]
    },
    setUsers (state, tasks) {
      state.users = tasks
    },
    fetchAdminsToView: (state, { usersId, page }) => {
      if (usersId[state.queryParams.limit - 1] !== undefined) state.pagination.nextPage = true
      else state.pagination.nextPage = false
      state.listAdmins[page] = usersId
    },
    fetchMessagesToView: (state, { messageId, page }) => {
      if (messageId[state.queryParams.limit - 1] !== undefined) state.pagination.nextPage = true
      else state.pagination.nextPage = false
      state.listMessages[page] = messageId
    },
    fetchmediamessagesToView: (state, { messageId, page }) => {
      if (messageId[state.queryParams.limit - 1] !== undefined) state.pagination.nextPage = true
      else state.pagination.nextPage = false
      state.listmediamessages[page] = messageId
    },
    deleteTask: (state, taskId) => {
      delete state.tasks[taskId]
      for (const i in state.listTasks) {
        for (const j in state.listTasks[i]) {
          if (state.listTasks[i][j] === taskId) {
            delete state.listTasks[i][j]
          }
        }
      }
    },
    fetchTasksToView: (state, { taskId, page }) => {
      if (taskId.length === state.queryParams.limit) state.pagination.nextPage = true
      else state.pagination.nextPage = false
      state.listTasks[page] = taskId
    },
    clearUsersToView: state => {
      delete state.listUsers
      state.listUsers = {}
    },
    clearUsers: state => {
      delete state.users
      state.users = {}
    },
    clearAllowedFields: state => {
      delete state.csv.allowedFields
      state.csv.allowedFields = {}
    },
    setGeoClusters (state, payload) {
      state.geoClusters.limit = payload.available_radius
      payload.geo_task_clusters
      ? state.geoClusters.items = payload.geo_task_clusters
      : state.geoClusters.items = [  [ payload ], ...state.geoClusters.items ]
    },
    clearChannelsToView: state => {
      delete state.listChannels
      state.listChannels = {}
    },
    clearPastChannelsToView: state => {
      delete state.listPastChannels
      state.listPastChannels = {}
    },
    clearMessagesToView: state => {
      delete state.listMessages
      state.listMessages = {}
    },
    clearMediaMessagesToView: state => {
      delete state.listmediamessages
      state.listmediamessages = {}
    },
    clearTasksView: state => {
      delete state.listTasks
      state.listTasks = {}
    },
    clearChannels: state => {
      delete state.channels
      state.channels = {}
    },
    clearCSVData: state => {
      delete state.csv.data
      state.csv.data = {}
    },
    fetchChannelsToView: (state, { channelId, page }) => {
      if (channelId[state.queryParams.limit - 1] !== undefined) state.pagination.nextPage = true
      else state.pagination.nextPage = false
      state.listChannels[page] = channelId
    },
    fetchPastChannelsToView: (state, { channelId, page }) => {
      if (channelId[state.queryParams.limit - 1] !== undefined) state.pagination.nextPage = true
      else state.pagination.nextPage = false
      state.listPastChannels[page] = channelId
    },
    fetchAllChannels: (state, channels) => {
      channels.map(channel => {
        if (!state.channels[channel.tg_id]) state.channels[channel.tg_id] = channel
      })
    },
    fetchAllMessages: (state, messages) => {
      if (state.queryParams.limit === messages.length) state.pagination.nextPage = true
      else state.pagination.nextPage = false
      messages.map(message => {
        if (message.photos.length) {
          for (var i in message.photos) {
            if (message.photos[i].file_location) {
              message.photos[i].file_location = config.servers.tgcp_static + message.photos[i].file_location
            }
          }
        }
        if (message.documents.length) {
          for (var j in message.documents) {
            if (message.documents[j].file_location) {
              message.documents[j].file_location = config.servers.tgcp_static + message.documents[j].file_location
            }
          }
        }
        if (!state.messages[message.message_id]) state.messages[message.message_id] = message
      })
    },
    clearQueryString: state => {
      delete state.queryParams
      state.queryParams = { limit: state.defaultParams.channellist_limit }
    },
    clearMessages: state => {
      delete state.messages
      state.messages = {}
    },
    updateStats: (state, stats) => {
      state.stats = stats
    },
    updateParsedStats: (state, stats) => {
      state.parsedStats = stats
    },
    csvReady: (state, percent) => {
      state.csv.ready = percent
    },
    setCSVname: (state, name) => {
      state.csv.filename = name
    },
    downloadcsv: (state, { data, type, from, allowedFields, format }) => {
      if (type) state.csv.type = type
      if (from) state.csv.from = from
      if (format) state.csv.format = format
      if (allowedFields) state.csv.allowedFields = allowedFields
      state.csv.data = data
    },
    setAutoDownload: (state, autostart) => {
      state.csv.autostart = autostart
    },
    fetchMessageReplies: (state, { messageId, replies }) => {
      if (!state.messages[messageId]) state.messages[messageId] = []
      replies.map(message => {

        if (message.photos.length) {
          for (var i in message.photos) {
            if (message.photos[i].file_location) {
              message.photos[i].file_location = config.servers.tgcp_static + message.photos[i].file_location
            }
          }
        }

        return message
      })
      if (!state.messages[messageId].replies) 
        state.messages[messageId].replies = replies
      else 
        state.messages[messageId].replies.push(...replies)
    },
    fetchGeoTasks: (state, tasks) => {
      state.geoTasks = tasks
    },
    deleteGeoTask: (state, task) => {
      state.geoTasks = state.geoTasks.filter(el => el.join(';') !== task)
    },
    getUserHistorical: (state, { id, data }) => {
      if (!state.users[id]) return
      state.users[id].historical = data
    },
    getChatHistorical: (state, { id, data }) => {
      if (!state.channels[id]) return
      state.channels[id].historical = data
    },
    clearKnownLinks: state => {
      delete state.knownLinks
      state.knownLinks = []
    },
    setKnownLinks: (state, links) => {
      state.knownLinks = links
    },
    setRelations: (state, relations) => {
      try {
        if (Array.isArray(relations['links_to'])) {
          state.relations['links_to'] = relations['links_to']
        }
        if (Array.isArray(relations['linked_from'])) {
          state.relations['linked_from'] = relations['linked_from']
        }
      } catch (error) {
        console.debug(error)
      }
    },
    setTranslatedCacheField (state, payload) {
      state.translatedCache.set(payload.key, payload.data)
    }
  },
  actions: {
    fetchRelations: async ({ commit, dispatch }, params) => {
      try {
        const type = params?.type || 'channel'
        const id = params?.id
        const response = await dispatch('fetcher/fetchRelations', `telegram://${type}/${id}`, {
          root: true
        })
        const linksTo = response?.data?.['links_to']
        const linkedFrom = response?.data?.['linked_from']
        commit('setRelations', {
          'links_to': linksTo,
          'linked_from': linkedFrom
        })
      } catch (error) {
        console.debug(error)
      }
    },
    fetchChannelRelations ({ dispatch }, id) {
      dispatch('fetchRelations', { id })
    },
    fetchUserRelations ({ dispatch }, id) {
      dispatch('fetchRelations', {
        type: 'user',
        id
      })
    },
    deleteGeoTask ({ commit }, task) {
      commit('deleteGeoTask', task)
    },
    checkError ({ commit, dispatch }, err) {
      if (err.response.status === 401) {
        if (err.response.data.code === 'TOKEN_EXPIRED') {
          dispatch('refresh', null, { root: true }).then(() => {
            if (err.response.callAgain) {
              // dispatch(err.response.callAgain.name, err.response.callAgain.argument, { root: true })
            }
          })
        }
      }
    },
    deleteTask: ({ commit, dispatch, rootState }, taskId) => apiRequest(`/tasks/` + taskId, 'DELETE'),
    addTaskFull ({ commit, dispatch, rootState }, preLine) {
      if (!preLine) return false
      let line = preLine.replace(/^((?:https?:\/\/)?.+?\/)\+(.+$)/i, '$1joinchat/$2')
      if (!line) return false
      // eslint-disable-next-line no-useless-escape
      var regexLinkCheck = new RegExp('(t\\.me|telegram\\.me|t-do\\.ru|tlgg\\.ru)/([A-Za-z0-9/_-]+)', 'i')
      var hashCheck = new RegExp(/^([a-zA-Z][\w-]{21}|[0-9a-zA-Z_][\w-]{15,16}|[0-9a-fA-F]{32})$/, 'i')
      var usernameCheck = new RegExp('^[a-zA-Z][a-zA-Z0-9_]{4,31}$', 'i')
      var geoCheck = new RegExp(/(([-]?\d+[.,]\d{1,6})(\d*)[;,]([-]?\d+[.,]\d{1,6})(\d*))/, 'i')
      var result = regexLinkCheck.exec(line)
      var rawTask = ''
      var geoVal = geoCheck.exec(line)
      if (geoVal) {
        return dispatch('addTask', {
          type: 'geo',
          task: geoVal[2] + ';' + geoVal[4]
        })
      }
      var rawTaskType = 'username'
      if (result) {
        rawTask = result[2]
      } else {
        if (line.indexOf('//') > -1) return false
        rawTask = line
        if (line[0] === '@') {
          rawTask = line.replace('@', '')
        }
      }
      const taskArray = rawTask.trimRight('/').split('/')
      if (taskArray.length > 2) {
        if (taskArray[0] === 's') {
          rawTaskType = 'username'
          rawTask = taskArray[1]
          if (!usernameCheck.exec(rawTask)) return false
        } else return false
      } else if (taskArray.length === 2 && taskArray[0] === 'joinchat') {
        rawTaskType = 'joinchat'
        rawTask = taskArray[1]
        if (!hashCheck.exec(rawTask)) return false
      } else if (taskArray.length === 2 && taskArray[0] === 's') {
        rawTaskType = 'username'
        rawTask = taskArray[1]
        if (!usernameCheck.exec(rawTask)) return false
      } else {
        rawTaskType = 'username'
        if (!usernameCheck.exec(rawTask)) return false
      }
      return dispatch('addTask', {
        type: rawTaskType,
        task: rawTask
      })
    },
    addTask ({ commit, dispatch, rootState }, newTask) {
      commit('startLoad', `taskAdd`)
      return apiRequest(`/tasks/`, 'POST', {}, undefined, newTask)
        .catch(err => {
          throw err
        })
        .finally(() => commit('stopLoad', `taskAdd`))
    },
    getTaskBotLinks (context, { taskId }) {
      return apiRequest(`/tasks/${taskId}/bot_links`, 'GET')
    },
    addTaskBotLinks (context, data) {
      const { taskId, links } = data

      const payload = {
        links
      }

      return apiRequest(
        `/tasks/${taskId}/bot_links`,
        'POST',
        {},
        undefined,
        payload
      )
    },
    async addGeoCluster ({ dispatch, commit }, task) {
      commit('startLoad', 'addGeoCluster')
      try {
        const { data } = await apiRequest(`/tasks/geo_task_clusters/`, 'POST', {}, undefined, task)
        await dispatch('fetchGeoClusters', { silentLoad: true })
        notify({
          title: $t('notifications.success'),
          text: $t('tgcp.tasks.cluser_added_msg'),
          type: 'success'
        })
        return data
      } catch (err) {
        throw err
      } finally {
        commit('stopLoad', 'addGeoCluster')
      }
    },
    async fetchGeoClusters ({ commit }, { silentLoad = false }) {
      !silentLoad && commit('startLoad', 'geoClusters')
      try {
        const { data } = await apiRequest(`/tasks/geo_task_clusters/`, 'GET')
        commit('setGeoClusters', data)
        return data
      } catch (err) {
        throw err
      } finally {
        commit('stopLoad', 'geoClusters')
      }
    },
    createBot ({ commit }, data) {
      const { id, botInfo } = data

      return apiRequest(`/tasks/${id}/bot_info`, 'PUT', {}, undefined, botInfo)
    },
    async editTask ({ commit }, task) {
      if (!task.id) {
        throw new Error('Task id is required')
      }

      if (task.sync_period === 'null') {
        task.sync_period = null
      }

      const response = await apiRequest(
        `/tasks/${task.id}`,
        'PATCH',
        {},
        undefined,
        {
          priority: task.priority,
          is_active: task.is_active,
          sync_period: task.sync_period,
          collect_media: task.collect_media
        }
      )

      commit('editTask', response.data)

      return response.data
    },
    async massTaskAdd ({ dispatch, rootState }, { tasks, selectedBot, needAddToCase }) {
      const promises = tasks.map(async task => {
        try {
          let result = null

          // @NOTE: For mass addition, you will need to rewrite
          if (selectedBot) {
            result = await dispatch('addTaskBotLinks', {
              taskId: selectedBot,
              links: [{
                type: task.type,
                task: task.task
              }]
            })
          } else {
            result = await dispatch('addTask', task)
          }

          if (selectedBot && result.status === 200) {
            result.status = 201
          }

          if (result.status === 200) {
            return ['already', task]
          }
          if (result.status === 201) {
            if (!needAddToCase) return ['success', task]
            try {
              const caseId = rootState.cases.currentCase
              const stored = {
                provider: 'tgcp',
                ext_id: result.data.id,
                type: 'task',
                comment: null,
                data: result.data
              }
              await dispatch('cases/storedObjectsAdd', { storedObject: stored, caseId }, { root: true })
              return ['caseAdded', task]
            } catch (err) {
              console.error(err)
              task.err = result
              return ['caseError', task]
            }
          }

          task.err = result
          return [false, task]
        } catch (err) {
          if (err.message.match('422')) {
            task.err = 'Synchronization period limit reached'
          }
          return [false, task]
        }
      })

      const res = (await Promise[Promise.allSettled ? 'allSettled' : 'all'](promises)).reduce((acc, { status, value: [state, task] }) => {
        if (status !== 'fulfilled' || !state) {
          acc.errors.push(task)
        } else if (state === 'already') acc.exists.push(task)
        else if (state === 'success' || state === 'caseAdded' || state === 'caseError') acc.created.push(task)
        return acc
      }, { created: [], exists: [], errors: [] })

      return res // { created, exists, errors }
    },
    fetchStats ({ commit, state, dispatch, rootState }, id) {
      if (!state.stats.hasOwnProperty('messages_count')) {
        commit('startLoad', `stats`)
      }
      apiRequest(`/stats`, 'GET')
        .then(data => {
          commit('updateStats', data.data)
        })
        .catch(err => {
          err.response.callAgain = { name: 'tgcp/fetchStats', argument: null }
          dispatch('checkError', err)
          if (err.response.status === 404) console.error(err)
        })
        .finally(() => {
          commit('stopLoad', `stats`)
        })
    },
    fetchParsedStats ({ commit, state }) {
      return new Promise((resolve, reject) => {
        apiRequest('/tools/tgstats', 'GET', {}, config.servers.fetcher_proxy)
          .then(res => {
            const data = res.data.sort((a, b) => a.users_count < b.users_count)
            commit('updateParsedStats', data)
            resolve(data)
          })
          .catch(err => console.debug(err))
      })
    },
    addEntitiesTask ({ commit, state, rootState, dispatch }, chatId) {
      return new Promise((resolve, reject) =>
        apiRequest(`/tools/tgcp/entities/` + chatId, 'POST', {}, config.servers.fetcher_proxy)
          .then(data => {
            resolve(true)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {})
      )
    },
    addEntitiesUserTask ({ commit, state, rootState, dispatch }, userId) {
      return new Promise((resolve, reject) =>
        apiRequest(`/tools/tgcp_user/entities/` + userId, 'POST', {}, config.servers.fetcher_proxy)
          .then(data => {
            resolve(true)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {})
      )
    },
    fetchEntitiesForTable ({ commit, state, rootState, dispatch }, { chatId, type, filter, userId }) {
      let params = '?for_vue_server=1'
      if (filter['type']) params += '&type=' + filter['type']
      if (type) params += '&type=' + type
      if (filter['offset']) params += '&offset=' + filter['offset']
      if (filter['page']) params += '&page=' + filter['page']
      if (filter['limit']) params += '&limit=' + filter['limit']
      if (filter['ascending']) params += '&ascending=' + filter['ascending']
      if (filter['byColumn']) params += '&byColumn=' + filter['byColumn']
      if (filter['orderBy']) params += '&orderBy=' + filter['orderBy']
      const url = chatId ? `/tools/tgcp/entities/${chatId}${params}` : `/tools/tgcp_user/entities/${userId}${params}`
      return new Promise((resolve, reject) =>
        apiRequest(url, 'GET', {}, config.servers.fetcher_proxy)
          .then(data => {
            let obj = data.data
            for (let i in obj['data']) {
              obj['data'][i]['joinlink'] = obj['data'][i]['value']
              if (obj['data'][i]['type'] === 'email') {
                obj['data'][i]['email'] = obj['data'][i]['value']
              }
              if (obj['data'][i]['type'] === 'phone') {
                obj['data'][i]['phone'] = obj['data'][i]['value']
              }
            }
            resolve(obj)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {})
      )
    },
    exportEntities ({ commit, state, rootState, dispatch }, { chatId, filter, userId}) {
      let params = '?'
      if (filter['type']) params += '&type=' + filter['type']
      const url = userId ? `/tools/tgcp_user/entities/${userId}/export${params}` : `/tools/tgcp/entities/${chatId}/export${params}`
      return new Promise((resolve, reject) =>
        apiRequest(url, 'GET', {}, config.servers.fetcher_proxy)
          .then(data => {
            resolve(data.data)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {})
      )
    },
    fetchEntities ({ commit, state, rootState, dispatch }, { chatId, filter, userId }) {
      let params = '?'
      if (filter['type']) params += '&type=' + filter['type']
      if (filter['offset']) params += '&offset=' + filter['offset']
      const url = userId ? `/tools/tgcp_user/entities/${userId}${params}` : `/tools/tgcp/entities/${chatId}${params}`
      return new Promise((resolve, reject) =>
        apiRequest(url, 'GET', {}, config.servers.fetcher_proxy)
          .then(data => {
            if (!filter && !filter['type']) {
              data.data.tg_id = chatId
              commit('addEntityDataStats', data.data)
            }
            resolve(data.data)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {})
      )
    },
    fetchEntitiesCount ({ commit, state, rootState, dispatch }, chatId) {
      commit('startLoad', `channel_${chatId}_entity_data`)
      return new Promise((resolve, reject) =>
        apiRequest(`/tools/tgcp/entities/` + chatId + '/count', 'GET', {}, config.servers.fetcher_proxy)
          .then(data => {
            data.data.tg_id = chatId
            commit('addEntityDataStats', data.data)
            resolve(data.data)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {
            commit('stopLoad', `channel_${chatId}_entity_data`)
          })
      )
    },
    fetchUserEntitiesCount ({ commit, state, rootState, dispatch }, userId) {
      commit('startLoad', `user_${userId}_entity_data`)
      return new Promise((resolve, reject) =>
        apiRequest(`/tools/tgcp_user/entities/` + userId + '/count', 'GET', {}, config.servers.fetcher_proxy)
          .then(data => {
            data.data.tg_id = userId
            commit('addUserEntityDataStats', data.data)
            resolve(data.data)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {
            commit('stopLoad', `user_${userId}_entity_data`)
          })
      )
    },
    fetchChannelsForTasksBySearchTerm ({ commit, state, rootState, dispatch }, keywords) {
      return apiRequest(`/tools/telegram-group/?search=` + encodeURIComponent(keywords), 'GET', {}, config.servers.fetcher_proxy)
    },
    fetchChannelListLanguages ({ state }) {
      return new Promise((resolve, reject) => {
        return apiRequest(`/chats/filters/language`, 'GET')
          .then(res => {
            var languages = res.data.language_codes.sort()
            resolve(languages)
          })
          .catch(err => reject(err))
      })
    },
    fetchChannelListCountries ({ state }) {
      return new Promise((resolve, reject) => {
        return apiRequest(`/chats/filters/country`, 'GET')
          .then(res => {
            var countries = res.data.country_codes
            resolve(countries)
          })
          .catch(err => reject(err))
      })
    },
    async fetchUserListCountries ({ state }) {
      const { data } = await apiRequest(`/users/available_countries`, 'GET')
      return data
    },
    fetchChannelListCategories ({ state }) {
      return new Promise((resolve, reject) => {
        return apiRequest(`/chats/filters/category`, 'GET')
          .then(res => {
            var result = res.data.categories
              .map(el => {
                return { id: el, name: categories[el].name }
              })
              .sort((a, b) => a.name > b.name)
            resolve(result)
          })
          .catch(err => reject(err))
      })
    },
    fetchChannelAvatarsById ({ commit, state, rootState, dispatch }, id) {
      if (state.channelAvatars[id]) {
        commit('stopLoad', `channel_${id}_avatars`)
        return
      }
      commit('startLoad', `channel_${id}_avatars`)
      apiRequest(`/chats/${id}/all_avatars`, 'GET')
        .then(data => {
          let receivedData = {}
          receivedData.tg_id = id
          receivedData.data = data.data
          receivedData.data.forEach(item => {
            const fileLocation = item.file_location
            item.file_location = config.servers.tgcp_static + fileLocation
          })
          commit('addChannelAvatar', receivedData)
        })
        .catch(() => {
          commit('addChannelAvatar', { error: true, tg_id: id })
        })
        .finally(() => {
          commit('stopLoad', `channel_${id}_avatars`)
        })
    },
    fetchUserAvatarsById ({ commit, state, rootState, dispatch }, id) {
      if (state.userAvatars[id]) {
        commit('stopLoad', `user_${id}_avatars`)
        return
      }
      commit('startLoad', `user_${id}_avatars`)
      apiRequest(`/users/${id}/all_avatars`, 'GET')
        .then(data => {
          data.data.tg_id = id
          data.data.forEach((e, i, data) => {
            data[i].file_location = config.servers.tgcp_static + e.file_location
          })
          commit('addUserAvatar', data.data)
        })
        .catch(() => {
          commit('addUserAvatar', { error: true, tg_id: id })
        })
        .finally(() => {
          commit('stopLoad', `user_${id}_avatars`)
        })
    },
    fetchMessageLink ({ commit, state, rootState }, { messageId, chatId }) {
      return new Promise((resolve, reject) => {
        apiRequest(`/messages/${chatId}/${messageId}/offset`, 'GET')
          .then(data => {
            let offset = data.data
            const limit = state.defaultParams.channellist_limit
            const page = Math.floor(offset / (limit - 1) + 1)
            offset = (limit - 1) * page - (limit - 1)
            resolve({
              id: chatId,
              message_id: messageId,
              message_pagination: { page, offset },
              tab: 'messages_tab'
            })
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    fetchMediaMessageLink ({ commit, state, rootState }, { messageId, chatId }) {
      if (state.mediamessages[messageId] && state.mediamessages[messageId].pagination) return
      commit('startLoad', `mediamessage_${messageId}_link`)
      apiRequest(`/messages/${chatId}/${messageId}/offset`, 'GET')
        .then(data => {
          data.data.message_id = messageId
          data.data.chat_id = chatId
          let offset = data.data
          const limit = state.defaultParams.channellist_limit
          const page = Math.floor(offset / (limit - 1) + 1)
          offset = (limit - 1) * page - (limit - 1)
          data.data.page = page
          data.data.offset = offset
          commit('fetchMediaMessageLink', data.data)
        })
        .catch(err => {
          console.debug(err)
        })
        .finally(() => {
          commit('stopLoad', `mediamessage_${messageId}_link`)
        })
    },
    getUserHistorical ({ commit, state }, id) {
      return new Promise((resolve, reject) => {
        apiRequest(`/users/${id}/historical`, 'GET')
          .then(res => {
            let historical = res.data
            commit('getUserHistorical', { id: id, data: historical })
            resolve(historical)
          })
          .catch(err => reject(err))
      })
    },
    async getChatHistorical ({ commit, state }, id) {
      const { data } = await apiRequest(`/chats/${id}/historical`, 'GET')
      commit('getChatHistorical', { id, data })
      return data
    },
    async fetchUsersByChat ({ commit }, { chatId, participantType = 'participant', participantStatus = 'all' }) {
      commit('startLoad', `chat_${chatId}`)
      let query = {
        min_id: 0,
        limit: 500
      }
      let users = {}
      while (true) {
        try {
          let { data } = await apiRequest(`/chats/${chatId}/${participantType}/${participantStatus}?${getQueryString(query)}`)
          if (!data.length || data.length === 1) break
          data.forEach(user => {
            users[user.tg_id] = user
          })
          query.min_id = data.pop().tg_id
        } catch (err) {
          console.dir(err)
          break
        }
      }
      return users
    },
    async fetchChatUserGeoChartById ({ commit, state, dispatch, rootState }, { id, query = null, save = true }) {
      commit('startLoad', `channel_${id}_ugc`)
      let queryString = query ? getQueryString(query) : ''
      try {
        // eslint-disable-next-line
        let { data: { audience_countries } } = await apiRequest(`/chats/${id}/audience/countries?${queryString}`, 'GET')
        // eslint-disable-next-line
        let data = { id, audience_countries }
        if (!state.channels[id]) {
          await dispatch('fetchChannelById', id)
        }
        if (save) {
          commit('addChatDataUserGeo', data)
        }
        // eslint-disable-next-line
        return audience_countries
      } catch (err) {
        console.debug(err)
        throw err
      } finally {
        commit('stopLoad', `channel_${id}_ugc`)
      }
    },
    async fetchChatUserActivityById ({ commit, state, dispatch, rootState }, { id, query }) {
      commit('startLoad', `channel_${id}_ua`)
      let currentParams = ''
      const params = new URLSearchParams(query)
      const paramsString = params.toString()
      if (paramsString) {
        currentParams = `?${paramsString}`
      }
      try {
        const { data } = await apiRequest(`/chats/${id}/activity/users${currentParams}`, 'GET')
        data.tg_id = id
        if (!state.channels[id]) {
          await dispatch('fetchChannelById', id)
        } 
        commit('addChatDataUserActivity', data)
      } 
      catch (err) {
        console.log(err)
      } 
      finally {
        commit('stopLoad', `channel_${id}_ua`)
      }
    },
    fetchChatForwardsById ({ commit, state, dispatch, rootState }, { id, query }) {
      commit('startLoad', `channel_${id}_ua`)
      let currentParams = ''
      const params = new URLSearchParams(query)
      const paramsString = params.toString()
      if (paramsString) {
        currentParams = `?${paramsString}`
      }
      apiRequest(`/chats/${id}/activity/forwards${currentParams}`, 'GET')
        .then(data => {
          var userData = data.data.forwards_activity
          userData.tg_id = id
          commit('addChatForwardsActivity', userData)
        })
        .finally(() => {
          commit('stopLoad', `channel_${id}_ua`)
        })
    },
    fetchChannelGeo ({ commit, state, dispatch, rootState }, id) {
      commit('startLoad', `channel_${id}_geo`)
      apiRequest(`/chats/${id}/geo`, 'GET')
        .then(data => {
          var userData = data.data
          userData.tg_id = id
          if (!state.channels[id]) {
            dispatch('fetchChannelById', id).then(commit('addChannelDataGeo', userData))
          } else {
            commit('addChannelDataGeo', userData)
          }
        })
        .catch(err => {
          console.debug(err)
          if (err.response.status === 404) {
          }
        })
        .finally(() => {
          commit('stopLoad', `channel_${id}_geo`)
        })
    },
    fetchUserGeo ({ commit, state, dispatch, rootState }, id) {
      commit('startLoad', `user_${id}_geo`)
      apiRequest(`/users/${id}/geo`, 'GET')
        .then(data => {
          var userData = data.data
          userData.tg_id = id
          if (!state.users[id]) {
            dispatch('fetchUserById', { id }).then(commit('addUserDataGeo', userData))
          } else {
            commit('addUserDataGeo', userData)
          }
        })
        .catch(err => {
          if (err.response.status === 404) {
          }
        })
        .finally(() => {
          commit('stopLoad', `user_${id}_geo`)
        })
    },
    fetchUsersGeoNearWithCoords ({ commit, state, dispatch }, query) {
      let queryString = query ? getQueryString(query) : ''
      // TODO fix gateway to dependent one
      return apiRequest(`/tools/rtusers?` + queryString, 'GET', {}, config.servers.asiris_api)
    },
    fetchUsersGeoNear ({ commit, state, dispatch }) {
      return apiRequest(`/users/geo/near?limit=500`, 'GET')
    },
    async fetchUserGeoNearExists ({ commit, state, dispatch }, id) {
      try {
        var { data } = await apiRequest(`/users/${id}/geo/near?limit=1`, 'GET')
      } catch {
        return []
      }
      return data
    },
    async fetchUserGeoNear ({ commit, state, dispatch }, id) {
      if (!state.users[id]) {
        await dispatch('fetchUserById', { id })
      }
      try {
        // TODO fix gateway to dependent one
        var { data } = await apiRequest(`/tools/rtusers/${id}`, 'GET', {}, config.servers.asiris_api)
      } catch (err) {
        throw err
      }
      return data
    },
    fetchUserChatsActivityById ({ commit, state, dispatch, rootState }, { id, query }) {
      commit('startLoad', `user_${id}_ca`)
      let currentParams = ''
      const params = new URLSearchParams(query)
      const paramsString = params.toString()
      if (paramsString) {
        currentParams = `?${paramsString}`
      }
      apiRequest(`/users/${id}/activity/chats${currentParams}`, 'GET')
        .then(data => {
          var userData = data.data
          userData.tg_id = id
          if (!state.users[id]) {
            dispatch('fetchUserById', { id }).then(commit('addUserDataChatsActivity', userData))
          } else {
            commit('addUserDataChatsActivity', userData)
          }
        })
        .catch(err => {
          if (err.response.status === 404) {
          }
        })
        .finally(() => {
          commit('stopLoad', `user_${id}_ca`)
        })
    },
    fetchUserMessagesChats: ({ state }, id) => apiRequest(`/users/${id}/activity/chats?limit=100`, 'GET'),
    fetchUserActivityById ({ commit, state, dispatch, rootState }, obj) {
      var id = obj[0]
      var type = obj[1]
      var query = getQueryString(obj[2])
      commit('startLoad', `user_${id}_activity_${type}`)
      return apiRequest(`/users/${id}/activity/${type}?${query}`, 'GET')
        .then(data => {
          var userData = data.data
          userData.tg_id = id
          userData.key = type
          commit('addUserDataActivity', userData)
        })
        .catch(err => {
          console.debug(err)
          if (err.response.status === 404) {
          }
        })
        .finally(() => {
          commit('stopLoad', `user_${id}_activity_${type}`)
        })
    },
    fetchUserPhoneAvailabilityById ({ commit, state, dispatch, rootState }, id) {
      commit('startLoad', `user_${id}_has_phone`)
      apiRequest(`/user/${id}/exists`, 'GET', {}, config.servers.tgresolve)
        .then(async res => {
          if (!state.users[id]) await dispatch('fetchUserById', { id })
          if (res.data.price === 0) {
            await dispatch('fetchUserPhoneById', id)
          }
          commit('userHavePhone', id)
        })
        .catch(() => {})
        .finally(() => {
          commit('stopLoad', `user_${id}_has_phone`)
        })
    },
    async fetchUserPhoneById ({ commit, state, dispatch, rootState }, id) {
      commit('startLoad', `user_${id}_phone`)
      try {
        const { data: { phone } } = await apiRequest(`/user/${id}/phone`, 'GET', {}, config.servers.tgresolve)
        commit('userPhone', { tg_id: id, phone })
        return phone
      } finally {
        commit('stopLoad', `user_${id}_phone`)
      }
    },
    async checkChannelUsers ({ commit }, id) {
      try {
        const { data } = await apiRequest(`/chats/${id}/additional_info`, 'GET')
        return data.resolve_required
      } catch (err) {
        throw err
      }
    },
    async resolveChannelUsers ({ commit }, id) {
      try {
        const { data } = await apiRequest(`/chats/${id}/additional_info`, 'POST')
        return data
      } catch (err) {
        throw err
      }
    },
    checkPhoneExists ({ state }, id) {
      return new Promise((resolve, reject) => {
        apiRequest(`/user/${id}/exists`, 'GET', {}, config.servers.tgresolve)
          .then(data => {
            resolve(data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    fetchUserById,
    async fetchUserByIdWithoutCommitting ({ commit, dispatch }, params = {}) {
      const data = await fetchUserById({ commit: () => {}, dispatch: () => {} }, params)
      return data
    },
    async fetchMessageById ({ commit, state, dispatch, rootState }, replyData) {
      const id = String(replyData.message_id)
      const chatId = String(replyData.chat_id)
      commit('startLoad', 'message_' + id)
      try {
        const { data } = await apiRequest('/messages/' + chatId + '/' + id, 'GET')
        data.tg_id = id
        commit('addMessageData', data)
        return data
      } catch (err) {
        console.error(err)
        throw err
      } finally {
        commit('stopLoad', `message_` + id)
      }
    },
    fetchMessageReposts ({ state, dispatch, rootState }, { chatId, messageId }) {
      apiRequest(`/messages/${chatId}/${messageId}/repost`, 'GET')
    },
    async fetchModalUserStatsById ({ commit, state, dispatch, rootState }, id) {
      const { data } = await apiRequest(`/users/${id}/stats`, 'GET')
      data.tg_id = id
      return data
    },
    async fetchUserStatsById ({ commit, state, dispatch, rootState }, id) {
      if (state.users[id] && state.users[id].stats) {
        return
      }

      commit('startLoad', 'user_' + id + '_stats')

      try {
        const { data } = await apiRequest(`/users/${id}/stats`, 'GET')
        data.tg_id = id
        if (!state.users[id]) {
          await dispatch('fetchUserById', { id })
        }
        commit('addUserDataStats', data)
      } finally {
        commit('stopLoad', `user_${id}_stats`)
      }
    },
    getFromCacheOrRequestUserChannelList ({ state, dispatch, rootState }, { id, access, offset = 0, limit = 20}) {
      // if (state.users['id']) {
      //  return Promise.resolve({ data: state.users['id'] })
      // } else {
      const query = getQueryString({offset, limit})
      return apiRequest(`/users/${id}/${access}/all?${query}`, 'GET')
      // }
    },
    getFromCacheOrRequestUserData ({ state, dispatch, rootState }, id) {
      if (state.users[id]) {
        return Promise.resolve({ data: state.users[id] })
      } else {
        return apiRequest(`/users/${id}`, 'GET')
      }
    },
    async getFromCacheOrRequestChannelData ({ state, dispatch, rootState }, id) {
      if (state.channels[id]) {
        return Promise.resolve({ data: state.channels[id] })
      } else {
        const data = await dispatch('fetchChannelById', id)
        return { data }
      }
    },
    getFromCacheOrRequestUserAvatar ({ state, dispatch, rootState }, id) {
      if (state.users['id'] && state.userAvatars[id]) {
        return Promise.resolve({ data: { file_location: state.userAvatars[id] } })
      } else {
        return apiRequest(`/users/${id}/avatar`, 'GET')
      }
    },
    getUserReplies ({ state, dispatch, rootState }, id) {
      return apiRequest(`/users/${id}/replies/`, 'GET').then(data => {
        var userData = data.data
        userData.tg_id = id
        return Promise.resolve(userData)
      })
    },
    async getChannelTopics ({ commit, state, dispatch }, id) {
      if (!state.channels[id]) {
        await dispatch('fetchChannelById', id)
      }

      commit('startLoad', 'channel_' + id + '_topics')

      try {
        const { data } = await apiRequest(`/chats/${id}/topics`, 'GET')
        console.log(data)
        data.tg_id = id
        data.topics = data.topics.reduce((res, item) => ({ ...res, [item.tg_id]: item}), {})
        commit('addChannelTopic', data)
      } finally {
        commit('stopLoad', `channel_${id}_topics`)
      }
    },
    getFullUserChannelList ({ commit, state, dispatch, rootState }, id) {
      return Promise.all(
        [dispatch('getFromCacheOrRequestUserChannelList', { id: id, access: 'admin' }), dispatch('getFromCacheOrRequestUserChannelList', { id: id, access: 'participant' })].map(reflect)
      ).then(function (values) {
        var channels = {}
        for (let i in values) {
          if (values[i].status === 'fulfilled') {
            var data = values[i].value.data
            channels = { ...data, ...channels }
            for (let j in channels) {
              var userData = Object.assign({}, channels[j])
              userData.activity = {}
              userData.answer_status = 'FOUND'
              commit('addChannelData', userData)
              commit('stopLoad', 'channel_' + userData.tg_id)
            }
          }
        }
        return Promise.resolve(channels)
      })
    },
    getFullUserData ({ state, dispatch, rootState }, id) {
      return dispatch('getFromCacheOrRequestUserData', id).then(function (values) {
        let user = Object.assign({}, values.data)
        if (user.avatar && user.avatar.hasOwnProperty('file_location')) {
          user.avatar_src = config.servers.tgcp_static + user.avatar.file_location
        }
        return Promise.resolve(user)
      })
    },
    async fetchUserRepliesById ({ commit, state, dispatch }, tgId) {
      if (!tgId) {
        return
      }

      const LOADING_KEY = `user_${tgId}_replies`

      commit('startLoad', LOADING_KEY)

      if (!state.users[tgId]) {
        await dispatch('fetchUserById', { id: tgId })
      }

      try {
        const { data } = await apiRequest(`/users/${tgId}/replies/`, 'GET')

        commit('addUserReplies', {
          data,
          tgId
        })

        return data
      } catch (error) {} finally {
        commit('stopLoad', LOADING_KEY)
      }
    },
    async fetchUsersToView ({ commit, dispatch, state }, users) {
      return new Promise((resolve, reject) => {
        let offsetPassed = router.currentRoute.value.query?.offset ?? 0
        let page = 0
        if (offsetPassed) {
          page = Math.floor(offsetPassed / (state.queryParams.limit - 1) + 1)
        } else page = ++Object.keys(state.listUsers).length
        if (Number(offsetPassed) === 0) page = 1
        let usersId
        if (Array.isArray(users)) {
          usersId = users.map(user => user.tg_id)
        } else {
          usersId = users.users.map(user => user.tg_id)
        }
        commit('fetchUsersToView', { usersId, page })
        resolve()
      })
    },
    async fetchAdminsToView ({ commit, dispatch, state }, users) {
      return new Promise((resolve, reject) => {
        let offsetPassed = router.currentRoute.value.query?.offset ?? 0
        let page = 0
        if (offsetPassed) {
          page = Math.floor(offsetPassed / (state.queryParams.limit - 1) + 1)
        } else page = ++Object.keys(state.listAdmins).length
        if (Number(offsetPassed) === 0) page = 1
        const usersId = users.map(user => user.tg_id)
        commit('fetchAdminsToView', { usersId, page })
        resolve()
      })
    },
    fetchMessagesToView ({ dispatch, commit, state }, messages) {
      return new Promise((resolve, reject) => {
        if (!messages[0]) reject(commit('fetchMessagesToView', { messageId: 0, page: 1 }))
        let offsetPassed = router.currentRoute.value.query?.offset || (messages[0] ? messages[0].offset : 0)
        let page = 0
        if (offsetPassed) {
          page = Math.floor(offsetPassed / (messages[0].limit - 1) + 1)
        } else page = ++Object.keys(state.listMessages).length
        if (Number(offsetPassed) === 0) page = 1
        const messageId = messages.map(message => message.message_id)
        commit('fetchMessagesToView', { messageId, page })
        resolve()
      })
    },
    fetchmediamessagesToView ({ dispatch, commit, state }, messages) {
      return new Promise((resolve, reject) => {
        let offsetPassed = router.currentRoute.value.query?.offset ?? 0
        let page = 0
        if (offsetPassed) {
          page = Math.floor(offsetPassed / (state.queryParams.limit - 1) + 1)
        } else page = ++Object.keys(state.listmediamessages).length
        if (Number(offsetPassed) === 0) page = 1
        const messageId = messages.map(message => message.message_id)
        commit('fetchmediamessagesToView', { messageId, page })
        resolve()
      })
    },
    fetchTasksToView ({ dispatch, commit, state }, tasks) {
      const offsetPassed = router.currentRoute.value.query?.offset ?? 0
      let page = 0
      if (offsetPassed) {
        page = Math.floor(offsetPassed / (state.queryParams.limit - 1) + 1)
      } else page = ++Object.keys(state.listTasks).length
      if (+offsetPassed === 0) page = 1
      const taskId = tasks.map(task => task.id)
      commit('fetchTasksToView', { taskId, page })
    },
    async fetchAllUsers ({ dispatch, commit, state, rootState }, searchParams = {}) {
      let query = searchParams
      const fetchingToView = query?.withoutFetchingToView
      if (fetchingToView) {
        let { withoutFetchingToView, ...currentParams } = query
        query = currentParams
      }
      commit('startLoad', 'users')
      
      if (Object.keys(query).length === 0) query = { ...state.queryParams, ...router.currentRoute.value.query }

      if (query.search) {
        query.search = unicode.normalize(query.search)
      }

      if (
        Array.isArray(query.search_fields) &&
        query.search_fields.length === 0
      ) {
        query.search_fields = [
          'first_name',
          'last_name',
          'username',
          'bio',
          'historical_usernames',
          'historical_first_names',
          'historical_last_names',
          'historical_bio'
        ]
      }
      if (query.search_fields && Array.isArray(query.search_fields)) query.search_fields = query.search_fields.join('&search_fields=')
      const queryString = getQueryString(query)
      try {
        let { data: users } = await apiRequest(`/users/?${queryString}`, 'GET')
        const participantType = query.participant_type === 'admin' ? 'Admin' : 'User'
        if (!fetchingToView) {
          await dispatch(`fetch${participantType}sToView`, users)
        }
        let current = []
        if (Array.isArray(users)) {
          current = users
        } else {
          current = users.users
        }
        users = current.map(user => {
          user.activity = {}
          if (user.avatar && user.avatar.file_location) user.avatar.file_location = config.servers.tgcp_static + user.avatar.file_location
          user.answer_status = 'FOUND'
          return user
        })
        commit('fetchAllUsers', current)
        return current
      } finally {
        commit('stopLoad', `users`)
      }
    },
    fetchChatsUsers ({ dispatch, commit, state, rootState }, [query, chatId, participantType, participantStatus]) {
      commit('startLoad', 'users')
      if (!query) query = Object.assign({}, state.queryParams, router.currentRoute.value.query)
      if (query.search_fields && Array.isArray(query.search_fields)) query.search_fields = query.search_fields.join('&search_fields=')
      const queryString = getQueryString(query)
      return new Promise((resolve, reject) => {
        let url = `/users/?${queryString}`
        apiRequest(url, 'GET')
          .then(res => {
            var users = res.data
            users =
              query.response_type === 'full'
                ? users.users.map(el => {
                  if (el.participant_info) {
                    el.user.participant_info = el.participant_info
                  }
                  if (el.user.avatar && el.user.avatar.file_location) el.user.avatar.file_location = config.servers.tgcp_static + el.user.avatar.file_location
                  return el.user
                })
                : users.map(user => {
                  user.activity = {}
                  if (user.avatar && user.avatar.file_location) user.avatar.file_location = config.servers.tgcp_static + user.avatar.file_location
                  user.answer_status = 'FOUND'
                  return user
                })
            participantType = participantType === 'admin' ? 'Admin' : 'User'
            dispatch(`fetch${participantType}sToView`, users)
              .then(() => {
                commit('fetchAllUsers', users)
                resolve(users)
              })
              .catch(err => console.debug(err) && reject(err))
              .finally(() => {})
          })
          .catch(err => {
            console.debug(err)
            if (err.response.status === 404) console.error(err)
            reject(err)
          })
          .finally(() => commit('stopLoad', `users`))
      })
    },
    fetchTaskByUsername ({ commit }, username) {
      commit('startLoad', 'task_' + username)
      let limit = 50
      return new Promise((resolve, reject) => {
        apiRequest(`/tasks/?task_type=username&limit=` + limit, 'GET')
          .then(async res => {
            var tasks = res.data
            var task = null
            var offset = 0
            while (tasks.length > 0) {
              task = tasks.find(el => el.task === username)
              if (task) {
                break
              } else {
                offset += limit
                res = await apiRequest(`/tasks/?limit=${limit}&offset=${offset}`, 'GET')
                tasks = res.data
              }
            }
            resolve(task)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {
            commit('stopLoad', 'task_' + username)
          })
      })
    },
    fetchGeoTasks ({ commit, state }) {
      commit('startLoad', 'tasks_geo')
      return new Promise((resolve, reject) => {
        apiRequest(`/tasks/geo_points`, 'GET')
          .then(res => {
            // commit('fetchGeoTasks', res.data)
            resolve(res.data.map(el => [...el.split(';')]))
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => commit('stopLoad', 'tasks_geo'))
      })
    },
    fetchTasksByType ({ commit }, query) {
      const queryString = getQueryString(query)
      return new Promise((resolve, reject) => {
        apiRequest(`/tasks/?${queryString}`, 'GET')
          .then(data => {
            let tasks = data.data
            resolve(tasks)
          })
          .catch(err => reject(err))
      })
    },
    async fetchUsersByTaskId ({ state, commit }, taskId) {
      const { data: { users } } = await apiRequest(`/tasks/${taskId}/users`, 'GET')
      const newUsers = users.map(user => {
        user.activity = {}
        if (user.avatar && user.avatar.file_location) user.avatar.file_location = config.servers.tgcp_static + user.avatar.file_location
        user.answer_status = 'FOUND'
        return user
      })
      setTimeout(() => { // hack: переносим функцию в макротаски (webApi), чтобы не ждать не самое важное
        const usersToStore = newUsers.filter(user => {
          return !(user.tg_id in state.users)
        }).reduce((acc, user) => {
          acc[user.tg_id] = user
          return acc
        }, {})
        if (!Object.keys(newUsers).length) return
        const allUsers = { ...state.users, ...usersToStore }
        commit('setUsers', allUsers)
      })
      return newUsers
    },
    async fetchAllTasks ({ state, dispatch, commit, rootState }, isRefreshing) {
      const getActualQuery = () => getQueryString({ order_type: 'desc', order_by: 'created', ...state.queryParams, ...router.currentRoute.value.query })
      const queryString = getActualQuery()
      if (!isRefreshing) {
        commit('startLoad', 'tasks')
      }
      try {
        const { data: tasks } = await apiRequest(`/tasks/?${queryString}`, 'GET')
        if (getActualQuery() !== queryString) {
          throw new Error('route-changed')
        }
        await dispatch('fetchTasksToView', tasks)
        commit('stopLoad', 'tasks')
        const newTasks = tasks.reduce((acc, task) => {
          acc[task.id] = task
          return acc
        }, {})
        if (!Object.keys(newTasks).length) return tasks
        const allTasks = { ...state.tasks, ...newTasks }
        commit('fetchTask', allTasks)
        return tasks
      } catch (err) {
        console.warn('fetchAllTasks:', err)
        if (err.message === 'route-changed' || (err.response && err.response.status === 404)) {
          throw new Error(err)
        }
      }
    },
    fetchTaskChats ({ dispatch, commit, state, rootState }, id) {
      commit('startLoad', 'taskChats_' + id)
      return new Promise((resolve, reject) => {
        apiRequest(`/tasks/${id}/chats`, 'GET')
          .then(data => {
            const chatTasks = data.data
            resolve(chatTasks)
          })
          .finally(() => {
            commit('stopLoad', 'taskChats_' + id)
          })
      })
    },
    async fetchTaskSyncHistory ({ dispatch, commit, state, rootState }, id) {
      const response = await apiRequest(`/tasks/${id}/sync_history`, 'GET')

      return response.data
    },
    fetchTaskById (_, id) {
      return apiRequest(`/tasks/${id}`, 'GET', {}) // tgcp
    },
    getAllUsers ({ commit, state }, query) {
      const queryString = getQueryString(query)
      return new Promise((resolve, reject) => {
        apiRequest(`/users/?${queryString}`, 'GET')
          .then(res => resolve(res.data))
          .catch(err => reject(err))
      })
    },
    fetchAllUsersByChatId ({ dispatch, commit, state, rootState }, chatId) {
      commit('startLoad', 'usersByChatId')
      state.queryParams['chat_id'] = chatId
      let queryString = Object.keys(state.queryParams)
        .map(key => key + '=' + state.queryParams[key])
        .join('&')
      apiRequest(`/users/?${queryString}`, 'GET').then(data => {
        const users = data.data
        dispatch('fetchUsersToView', users)
          .then(() => {
            commit('fetchAllUsers', users)
          })
          .finally(() => {
            commit('stopLoad', 'usersByChatId')
          })
      })
    },
    fetchChannelAvatarById ({ commit, state, dispatch, rootState }, id) {
      if (!id) return
      if (state.channelAvatars[id]) {
        commit('stopLoad', `channel_${id}_avatar`)
        return
      }
      commit('startLoad', `channel_${id}_avatar`)
      apiRequest(`/chats/${id}/avatar`, 'GET')
        .then(data => {
          data.data.tg_id = id
          if (data.data.file_location) {
            data.data.file_location = config.servers.tgcp_static + data.data.file_location
          }
          commit('addChannelAvatar', data.data)
        })
        .catch(err => {
          err.response.callAgain = { name: 'tgcp/fetchChannelAvatarById', argument: id }
          dispatch('checkError', err)
        })
        .finally(() => {
          commit('stopLoad', `channel_${id}_avatar`)
        })
    },
    async fetchChannelWordsStatById ({ commit, dispatch }, { id, query }) {
      commit('startLoad', `channel_${id}_words`)
      try {
        const queryString = getQueryString(query)
        const { data } = await apiRequest(`/tgcp/words/${id}?${queryString}`, 'GET', {}, config.servers.tools)
        commit('addChannelWordsStatById', { payload: data, id })
        return data
      } catch (err) {
        throw err
      } finally {
        commit('stopLoad', `channel_${id}_words`)
      }
    },
    fetchChannelActivityById ({ commit, dispatch, state, rootState }, obj) {
      var id = obj[0]
      var type = obj[1]
      var query = getQueryString(obj[2])
      commit('startLoad', `channel_${id}_activity_${type}`)
      apiRequest(`/chats/${id}/activity/${type}?${query}`, 'GET')
        .then(data => {
          var userData = data.data
          userData.tg_id = id
          userData.key = type
          commit('addChannelDataActivity', userData)
        })
        .catch(err => {
          err['response'].callAgain = { name: 'tgcp/fetchChannelActivityById', argument: obj }
          dispatch('checkError', err)
          if (err.response.status === 404) {
          }
        })
        .finally(() => {
          commit('stopLoad', `channel_${id}_activity_${type}`)
        })
    },
    fetchChannelAudienceById ({ commit }, obj) {
      var id = obj[0]
      var type = obj[1]
      commit('startLoad', `channel_${id}_audience_${type}`)
      apiRequest(`/tools/tg_audience/${id}/${type}/`, 'GET', {}, config.servers.fetcher_proxy)
        .then(data => {
          var userData = data.data
          userData.tg_id = id
          userData.key = type
          commit('addChannelDataAudience', userData)
        })
        .catch(() => {})
        .finally(() => {
          commit('stopLoad', `channel_${id}_audience_${type}`)
        })
    },
    getChannelTasks ({ commit, state }, id) {
      return new Promise((resolve, reject) => {
        apiRequest(`/chats/${id}/tasks/`, 'GET')
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
      })
    },
    createChannelTask ({ commit, state }, id) {
      return new Promise((resolve, reject) => {
        apiRequest(`/chats/${id}/tasks/create`, 'GET')
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
      })
    },
    fetchChannelById,
    fetchChannelByIdWithoutCommitting ({ commit, state, dispatch, rootState }, id) {
      return fetchChannelById({ commit: () => {}, state, dispatch, rootState }, id)
    },
    async fetchChannelStatsById ({ commit, state, dispatch, rootState }, id) {
      commit('startLoad', 'channel_' + id + '_stats')
      try {
        let { data } = await apiRequest(`/chats/${id}/stats`, 'GET')
        data.tg_id = id
        if (!state.channels[id]) {
          await dispatch('fetchChannelById', id)
        }
        commit('addChannelDataStats', data)
        return data
      } catch (err) {
        throw err
      } finally {
        commit('stopLoad', `channel_${id}_stats`)
      }
    },
    async addParamToQuery ({ commit }, param) {
      const oldQuery = router.currentRoute.value.query
      const newQuery = { ...oldQuery, ...param }
      Object.keys(newQuery).forEach((k) => !newQuery[k] && delete newQuery[k])
      await router.replace({ query: newQuery})
    },
    addHiddenParamToQuery ({ commit }, param) {
      commit('addHiddenParamToQuery', param)
    },
    setPage ({ commit }, page) {
      return commit('setPage', page)
    },
    setPageWithType ({ commit }, page) {
      commit('setPageWithType', page)
    },
    clearUsersToView ({ commit }) {
      commit('clearUsersToView')
    },
    clearChannelsToView ({ commit }) {
      commit('clearChannelsToView')
    },
    clearPastChannelsToView ({ commit }) {
      commit('clearPastChannelsToView')
    },
    clearMessagesToView ({ commit }) {
      commit('clearMessagesToView')
    },
    searchByPhone ({ commit, state, rootState }, phone) {
      return apiRequest(`/phone/${phone}`, 'GET', {}, config.servers.tgresolve)
        .then(data => {
          var users = data.data
          var userData = users
          userData.activity = {}
          userData.avatar_src = ''
          userData.answer_status = 'FOUND'
          commit('addUserData', userData)
          return new Promise((resolve, reject) => {
            resolve(users)
          })
        })
        .catch(err => {
          return new Promise((resolve, reject) => {
            reject(err)
          })
        })
    },
    searchByUsernameOnline ({ commit, state, rootState }, nickname) {
      return apiRequest(`/username/${nickname}`, 'GET', {}, config.servers.tgresolve)
        .then(data => {
          var users = data.data
          var userData = users
          userData.activity = {}
          userData.avatar_src = ''
          userData.answer_status = 'FOUND'
          commit('addUserData', userData)
          return new Promise((resolve, reject) => {
            resolve(users)
          })
        })
        .catch(err => {
          return new Promise((resolve, reject) => {
            reject(err)
          })
        })
    },
    searchByTGid ({ commit, state, rootState }, userName) {
      return apiRequest(`/users/${userName}`, 'GET')
        .then(data => {
          var users = [data.data]
          for (var i in users) {
            var userData = users[i]
            userData.activity = {}
            userData.avatar_src = ''
            userData.answer_status = 'FOUND'
            commit('addUserData', userData)
          }
          return new Promise((resolve, reject) => {
            resolve(users)
          })
        })
        .catch(err => {
          if (err.response.status === 404) console.error(err)
        })
    },
    searchByUserName ({ commit, state, rootState }, userName) {
      return apiRequest(`/users/?search=${userName}&search_fields=username`, 'GET')
        .then(data => {
          var users = data.data
          for (var i in users) {
            var userData = users[i]
            userData.activity = {}
            userData.avatar_src = ''
            userData.answer_status = 'FOUND'
            commit('addUserData', userData)
          }
          return new Promise((resolve, reject) => {
            resolve(users)
          })
        })
        .catch(err => {
          if (err.response.status === 404) console.error(err)
        })
    },
    searchChannelsByUsername ({ commit, state, rootState }, query) {
      const queryString = getQueryString(query)
      return apiRequest(`/chats/?${queryString}`, 'GET')
        .then(res => {
          const chats = res.data
          return new Promise((resolve, reject) => {
            resolve(chats)
          })
        })
        .catch(err => {
          return new Promise((resolve, reject) => {
            reject(err)
          })
        })
    },
    fetchAllChannels ({ dispatch, commit, state, rootState }, query) {
      return new Promise((resolve, reject) => {
        commit('startLoad', 'channels')
        const localQuery = { ...query }

        localQuery.search = unicode.normalize(localQuery.search)

        if (
          Array.isArray(localQuery.search_fields) &&
          query.search_fields.length === 0
        ) {
          localQuery.search_fields = [
            'username',
            'name',
            'description',
            'joinlinks',
            'historical_names',
            'historical_usernames',
            'historical_descriptions'
          ]
        }
        if (localQuery.search_fields && Array.isArray(localQuery.search_fields)) localQuery.search_fields = localQuery.search_fields.join('&search_fields=')
        const queryString = getQueryString(localQuery)
        apiRequest(`/chats/?${queryString}`, 'GET')
          .then(data => {
            var chats = data.data
            chats.forEach((e, i, chats) => {
              chats[i].activity = {}
              chats[i].audience = {}
              if (chats[i].avatar && chats[i].avatar.file_location) chats[i].avatar.file_location = config.servers.tgcp_static + chats[i].avatar.file_location
              chats[i].answer_status = 'FOUND'
              chats[i].stats = {}
            })
            dispatch('fetchChannelsToView', chats)
              .then(() => {
                commit('fetchAllChannels', chats)
                resolve(chats)
              })
              .finally(() => {
                commit('stopLoad', `channels`)
              })
          })
          .catch(err => {
            console.debug(err)
            if (err.response.status === 404) console.error(err)
            reject(err)
          })
      })
    },
    async fetchClusterChats ({ commit }, { id, query }) {
      const { data: { chats } } = await apiRequest(`/tasks/geo_task_clusters/${id}/chats?${getQueryString(query)}`, 'GET')
      return chats.map(chat => {
        if (chat.avatar && chat.avatar.file_location) chat.avatar = config.servers.tgcp_static + chat.avatar.file_location
        return chat
      })
    },
    fetchChannelsByUserId ({ dispatch, commit, state, rootState }, { id, query, participantType, participantStatus, withoutFetchingToView = false }) {
      commit('startLoad', 'channelsbyuserid' + participantStatus)

      if (withoutFetchingToView && participantType === 'owner') {
        participantStatus = 'all'
      }
      
      const queryString = getQueryString(query)
      return new Promise((resolve, reject) => {
        apiRequest(`/users/${id}/${participantType}/${participantStatus}?${queryString}`, 'GET')
          .then(({ data }) => {
            var chats = data.map(chat => {
              if (chat.avatar && chat.avatar.file_location) chat.avatar.file_location = config.servers.tgcp_static + chat.avatar.file_location
              chat.activity = {}
              chat.avatar_src = ''
              chat.answer_status = 'FOUND'
              chat.stats = {}
              return chat
            })
            const pastChannels = participantStatus === 'current' ? '' : 'Past'
            if (!withoutFetchingToView) {
              dispatch(`fetch${pastChannels}ChannelsToView`, chats)
              .then(() => {
                commit('fetchAllChannels', chats)
                resolve(chats)
              })
              .finally(() => {
                commit('stopLoad', 'channelsbyuserid' + participantStatus)
              })
            } else {
              resolve(chats)
            }
          })
          .catch(err => {
            console.debug(err)
          })
      })
    },
    async fetchAllMessages ({ dispatch, commit, state, rootState }, queryParam) {
      let query = queryParam ? { ...queryParam } : null
      let offset
      commit('startLoad', 'message')
      if (!query) query = Object.fromEntries(Object.entries({ ...state.queryParams, ...router.currentRoute.value.query }).filter(([_, v]) => v != null && v != ''))

      if (query.search) {
        query.search = unicode.normalize(query.search)
      }

      if (query.to_date === dayjs().format('YYYY-MM-DD')) {
        query.to_date = undefined
      }

      if ((query.search === '' || !query.search) && (query.from_date || query.to_date) && !(query.chat_id || query.user_id)) {
        notify({ group: 'general', text: $t('tgcp.messages.empty_search_date_ignored'), type: 'warn' })
        query.from_date = undefined
        query.to_date = undefined
      }
      
      if (query.messageId && query['chat_id']) {
        offset = (await dispatch('fetchMessageLink', {
          messageId: query.messageId,
          chatId: query['chat_id']
        })).message_pagination.offset
        const { messageId, ...withoutId } = query
        query = withoutId
      }
      let localQuery = offset ? { ...query, offset } : query
      if (query.search && !query.offset) {
        const { offset, ...withoutOffset } = query
        localQuery = withoutOffset
      }
      const queryString = getQueryString(localQuery)
      try {
        let { data: messages = [] } = await apiRequest(`/messages/?${queryString}`, 'GET')
        messages = messages.map(el => {
          el.limit = query.limit
          el.offset = query.offset
          return el
        })
        commit('fetchAllMessages', messages)
        return messages
      } catch (err) {
        throw err
      } finally {
        commit('stopLoad', `message`)
      }
    },
    async fetchMessagesForLink ({ dispatch }, { messageId, chatId, query }) {
      let localQuery = query
      const { message_pagination: { offset } } =  await dispatch('fetchMessageLink', { messageId, chatId })
      localQuery.offset = offset
      dispatch('fetchAllMessages', localQuery)
    },
    fetchMessageReplies ({ commit, state, rootState }, { message, offset = 0 }) {
      const id = message.message_id
      const chatId = message.chat_id
      const repliesCached = state.messages[id]
      const off = offset ? ('?offset=' + offset) : ''
      const fetchReplies = (async () => {
        const { data } = await apiRequest(`/messages/${chatId}/${id}/replies${off}`, 'GET')
        commit('fetchMessageReplies', { messageId: id, replies: data })
        return data
      })()
      return (repliesCached && repliesCached.replies) || fetchReplies
    },
    fetchAllmediamessages ({ dispatch, commit, state, rootState }, query) {
      commit('startLoad', 'message')
      if (!query) query = Object.assign({}, state.queryParams, router.currentRoute.value.query)
      const queryString = getQueryString(query)
      return new Promise((resolve, reject) => {
        apiRequest(`/messages/?${queryString}`, 'GET')
          .then(data => {
            var messages = data.data
            dispatch('fetchmediamessagesToView', messages)
              .then(() => {
                commit('fetchAllMessages', messages)
                resolve(messages)
              })
              .finally(() => {
                commit('stopLoad', `message`)
              })
          })
          .catch(err => {
            console.debug(err)
            err.response.callAgain = { name: 'tgcp/fetchAllMessages', argument: null }
            dispatch('checkError', err)
            if (err.response.status === 404) console.error(err)
            reject(err)
          })
      })
    },
    async fetchChannelsToView ({ commit, state }, chats) {
      return new Promise((resolve, reject) => {
        let offsetPassed = router.currentRoute.value.query?.offset ?? 0
        let page = 0
        if (offsetPassed) {
          page = Math.floor(offsetPassed / (state.queryParams.limit - 1) + 1)
        } else page = ++Object.keys(state.listChannels).length
        if (Number(offsetPassed) === 0) page = 1
        const channelId = chats.map(chat => chat.tg_id)
        commit('fetchChannelsToView', { channelId, page })
        resolve()
      })
    },
    async fetchPastChannelsToView ({ commit, state }, chats) {
      return new Promise((resolve, reject) => {
        let offsetPassed = router.currentRoute.value.query?.offset ?? 0
        let page = 0
        if (offsetPassed) {
          page = Math.floor(offsetPassed / (state.queryParams.limit - 1) + 1)
        } else page = ++Object.keys(state.listPastChannels).length
        if (Number(offsetPassed) === 0) page = 1
        const channelId = chats.map(chat => chat.tg_id)
        commit('fetchPastChannelsToView', { channelId, page })
        resolve()
      })
    },
    getChannelByJoinlink ({ state, rootState }, joinlink) {
      return new Promise(async (resolve, reject) => {
        let message = null
        try {
          message = await apiRequest(`/chats/?search=${joinlink}&search_fields=username&search_fields=joinlinks`, 'GET')
        } catch (err) {
          reject(err)
        }
        resolve(message.data)
      })
    },
    getMessageById ({ state, rootState }, { messageId, chatId }) {
      return new Promise(async (resolve, reject) => {
        let message = null
        try {
          message = await apiRequest(`/messages/${chatId}/${messageId}`, 'GET')
        } catch (err) {
          reject(err)
        }
        resolve(message.data)
      })
    },
    getMessageContext ({ state, rootState }, { messageId, chatId, limit }) {
      return new Promise(async (resolve, reject) => {
        let messageOffset = null
        try {
          messageOffset = await apiRequest(`/messages/${chatId}/${messageId}/offset`, 'GET')
          if (messageOffset.data - Math.floor(limit / 2) > 0) {
            messageOffset = messageOffset.data - Math.floor(limit / 2)
          } else {
            messageOffset = messageOffset.data
            limit = Math.floor(limit / 2)
          }
        } catch (err) {
          reject(err)
        }
        let messages = {}
        try {
          messages = await apiRequest(`/messages/?chat_id=${chatId}&limit=${limit}&offset=${messageOffset}`, 'GET')
        } catch (err) {
          reject(err)
        }
        resolve(messages.data)
      })
    },
    async getMessagesReplies ({ state, rootState }, messages) {
      let repliedMessages = []
      for (let message of messages) {
        if (message.reply_msg_id) {
          let replied = ''
          try {
            replied = await apiRequest(`/messages/${message.chat_id}/${message.reply_msg_id}`, 'GET')
          } catch (err) {
            if (err) continue
          }
          const already = messages.filter(message => message.message_id === replied.data.message_id)
          already.length === 0 && (await repliedMessages.push(replied.data))
        }
      }
      return messages.concat(repliedMessages)
    },
    async fetchFeed ({ state, rootState }, filters) {
      let messages = []
      let query = ''
      for (let filterName in filters) {
        let value = filters[filterName]
        if (filterName === 'chat_id') {
          for (let i in value) {
            query += filterName + '=' + value[i] + '&'
          }
        } else {
          query += filterName + '=' + value + '&'
        }
      }
      let feedMessages = await apiRequest(`/messages/?${query}`, 'GET')
      return messages.concat(feedMessages.data)
    },
    getStats ({ state, rootState }, { id, type }) {
      return new Promise((resolve, reject) => {
        let reqArray = [apiRequest(`/${type}/${id}/stats`, 'GET')]
        Promise.all(reqArray)
          .then(values => {
            resolve(Object.assign({}, values[0].data))
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getAllChatUsers ({ state, commit }, params) {
      var { chatId, query, participantType, participantStatus } = params
      return new Promise((resolve, reject) => {
        if (!chatId || !query || !participantType || !participantStatus) reject(new Error('Missing params'))
        const queryString = getQueryString(query)
        commit('startLoad', 'getAllChatsUsers')
        if (participantType === 'owner') participantStatus = 'all'
        return apiRequest(`/chats/${chatId}/${participantType}/${participantStatus}?${queryString}`, 'GET')
          .then(res => {
            var users = res.data
            users = users.map(el => {
              if (el.participant_info) {
                el.user.participant_info = el.participant_info
              }
              if (el.avatar && el.avatar.file_location) el.avatar.file_location = config.servers.tgcp_static + el.avatar.file_location
              el.activity = {}
              el.answer_status = 'FOUND'
              return el
            })
            commit('fetchAllUsers', users)
            resolve(users)
          })
          .catch(err => reject(err))
          .finally(() => commit('stopLoad', 'getAllChatsUsers'))
      })
    },
    async getOwnersAndFormers ({ state, commit }, params) {
      let { chatId, formersLimit } = params
      if (!chatId) throw new Error('Empty chatId')
      if (!formersLimit) { formersLimit = 20 }
      commit('startLoad', 'getAllChatsUsers')
      const transformUsers = (el) => {
        if (el.participant_info) {
          el.user.participant_info = el.participant_info
        }
        if (el.avatar && el.avatar.file_location) el.avatar.file_location = config.servers.tgcp_static + el.avatar.file_location
        el.activity = {}
        el.answer_status = 'FOUND'
        return el
      }
      let users = []
      try {
        const currentUserRes = await apiRequest(`/chats/${chatId}/owner/current?limit=20`, 'GET')
        const owners = currentUserRes.data.map(el => transformUsers(el))
        if (owners && owners.length === 0) {
          owners.push({ tg_id: -1 })
        }
        const formerUserRes = await apiRequest(`/chats/${chatId}/owner/former?limit=${formersLimit}`, 'GET')
        const formers = formerUserRes.data.map(el => transformUsers(el))
        users = [...owners, ...formers]
        commit('fetchAllUsers', users)
        return users
      } catch (e) {
        throw e
      } finally {
        commit('stopLoad', 'getAllChatsUsers')
      }
    },
    async getChannelName ({ state, dispatch }, messages) {
      let chatIDs = [
        ...new Set(messages.map(message => message.chat_id).filter(el => el)),
        ...new Set(messages.map(message => message.fwd_channel_id).filter(el => el))
      ]
      let chats = {}

          let data = await dispatch('getBatchChats', { IDs: chatIDs })
          data.forEach(chat => {
            chats[chat.tg_id] = chat
          })
      for (let i = 0; i < messages.length; i++) {
        let message = messages[i]
        let chat = chats[message.chat_id] || state.channels[message.chat_id]
        if (!chat) {
          try {
            let { data } = await apiRequest(`/chats/${message.chat_id}`, 'GET')
            chats[data.tg_id] = data
            message.chatName = data.name
            message.channelUsername = data.username
            if (message.channelUsername) {
              message.link = `https://t.me/${data.username}/${message.message_id}`
            }
          } catch {
            continue
          }
        } else {
          message.chatName = chat.name
          message.channelUsername = chat.username
            if (message.channelUsername) {
              message.link = `https://t.me/${chat.username}/${message.message_id}`
            }
          if (message.fwd_channel_id) {
            const fwdChat = chats[message.fwd_channel_id] || state.channels[message.fwd_channel_id]
            message.fwd_channel_name = fwdChat ? fwdChat.name : ''
          }
        }
      }
      return messages
    },
    async getBatchUsers ({ commit }, { IDs, cache = false }) {
      if (!IDs.length) return []
      try {
        let { data } = await apiRequest(`/users/many`, 'POST', {}, undefined, { items: IDs })
        data = data.map(el => {
          if (el.avatar && el.avatar.file_location) el.avatar.file_location = config.servers.tgcp_static + el.avatar.file_location
          return el
        })
        if (cache) commit('fetchAllUsers', data)
        return data
      } catch (err) {
        console.debug(err)
        throw err
      }
    },
    async getBatchChats ({ commit }, { IDs, cache = false }) {
      if (!IDs.length) return []
      const limit = 200
      let chats = []
      for (let i = 0; i < IDs.length; i += limit) {
        try {
          let { data } = await apiRequest(
            `/chats/many`,
            'POST',
            {},
            undefined,
            { items: IDs.slice(i, i + limit < IDs.length ? i + limit : IDs.length) }
          )
          chats = [
              ...data.map(el => {
              if (el.avatar && el.avatar.file_location) el.avatar.file_location = config.servers.tgcp_static + el.avatar.file_location
              return el
            }),
            ...chats
          ]
          if (cache) commit('fetchAllChannels', data)
        } catch (err) {
          continue
        }
      }
      return chats

    },
    async getUserName ({ dispatch, state }, messages) {
      let userIDs = [ ...new Set(messages.map(el => el.user_id).filter(el => el)) ]
      let users = {}
      let limit = 200
      for (let i = 0; i < userIDs.length; i += limit) {
        try {
          let data = await dispatch('getBatchUsers', { IDs: userIDs.slice(i, i + limit < userIDs.length ? i + limit : userIDs.length) })
          data.forEach(user => {
            users[user.tg_id] = user
          })
        } catch {
          break
        }
      }
      for (let message of messages) {
        if (!message.user_id || String(message.user_id) === 'null') continue
        let user = users[message.user_id] || state.users[message.user_id]
        if (!user) {
          try {
            let { data } = await apiRequest(`/users/${message.user_id}`, 'GET')
            users[data.tg_id] = data
            message.username = data.username || ''
            message.first_name = data.first_name || ''
            message.last_name = data.last_name || ''
          } catch {
            continue
          }
        } else {
          message.username = user.username || ''
          message.first_name = user.first_name || ''
          message.last_name = user.last_name || ''
        }
      }
      return messages
    },
    async downloadcsvUserChats ({ dispatch, commit, state, rootState }, { query, allowedFields, participantType, participantStatus, type, id, from, localizeFields, name }) {
      if (state.csv.ready > 0 && state.csv.ready < 100) return
      dispatch('clearCSVData')
      commit('csvReady', 50)
      commit('setCSVname', name)
      let fullData = []
      const final = rootState.settings.limits['asiris.export_user_chats_limit']
      const limit = 50
      for (let i = 0; i < final; i += limit) {
        query.offset = i
        query.limit = limit
        const queryString = Object.keys(query)
          .map(key => key + '=' + query[key])
          .join('&')
        try {
          let { data } = await apiRequest(`/users/${id}/${participantType}/${participantStatus}?${queryString}`, 'GET')
          if (data.length === 0) break
          for (let j = 0; j < data.length; j++) {
            let addition = {}
            try {
              addition = await dispatch('getStats', { id: data[j].tg_id, type })
            } catch (err) {
              console.debug(err)
              addition = {}
            }
            await fullData.push(Object.assign({}, addition, data[j]))
          }
        } catch {
          continue
        }
      }
      fullData = fullData.map(obj => {
        obj.first_message_date = dayjsCurrentTimestamp({ date: obj.first_message_date, timezone: rootState.settings.timezone })
        obj.last_message_date = dayjsCurrentTimestamp({ date: obj.last_message_date, timezone: rootState.settings.timezone })
        obj.last_sync = dayjsCurrentTimestamp({ date: obj.last_sync, timezone: rootState.settings.timezone })
        obj = removeEscapeCharacters(obj)
        if (localizeFields) obj = localizeCSVFields(obj, type)
        if (allowedFields) obj = filterFields(obj, allowedFields)
        return obj
      })
      commit('csvReady', 100)
      commit('downloadcsv', { data: fullData, type: type, from: from })
    },
    resolvePhoneNumber ({ commit }, { id }) {
      return apiRequest(`/user/${id}/phone`, 'GET', {}, config.servers.tgresolve)
    },
    async downloadcsvChatUsers ({ dispatch, commit, state, rootState }, {
      query,
      type,
      allowedFields,
      from,
      localizeFields,
      resolvePhoneNumbers,
      name
    }) {
      if (state.csv.ready > 0 && state.csv.ready < 100) return
      dispatch('clearCSVData')
      commit('csvReady', 40)
      commit('setCSVname', name)
      let fullData = []
      let userMinID = 0
      const final = rootState.settings.limits['asiris.export_chat_users_limit']
      const limit = 100
      if (query.search_fields && Array.isArray(query.search_fields)) query.search_fields = query.search_fields.join('&search_fields=')
      for (let i = 0; i < final; i += limit) {
        query.offset = i
        query.limit = limit
        const queryString = getQueryString(query)
        try {
          let users
          if (!query.search) {
            const { data } = await apiRequest(`/chats/${query.chat_id}/${query.participant_type}/${query.participant_status}?min_id=${userMinID}&limit=${limit}`, 'GET')
            users = [...data]
            if (users.length !== 0) {
              userMinID = users[users.length - 1].tg_id + 1
            } else break
          } else {
            const { data } = await apiRequest(`/${type}/?${queryString}`, 'GET')
            users = data.users || data
          }
          if (users.length === 0) break
          for (let j = 0, len = users.length; j < len; j++) {
            let addition = {}
            let user = flatternObj(users[j])
            try {
              addition = await dispatch('getStats', { id: user.tg_id, type })
            } catch (err) {
              console.debug(err)
              continue
            }
            if (resolvePhoneNumbers) {
              user.phone_number = ''
              try {
                const { data: { phone } } = await dispatch('resolvePhoneNumber', { id: user.tg_id })
                user.phone_number = phone
              } catch (error) {
                if (
                  error.hasOwnProperty('response') &&
                  error.response.status === 403 &&
                  error.response.hasOwnProperty('data') &&
                  error.response.data.hasOwnProperty('code') &&
                  error.response.data.code === 'NOT_ENOUGH_FUNDS'
                ) {
                  user.phone_number = $t('balance.non_enough_funds_error')
                }
                console.debug(error)
              }
            }
            await fullData.push(flatternObj(Object.assign({}, addition, user)))
          }
        } catch (err) {
          console.debug(err)
          continue
        }
      }
      fullData = fullData.map(obj => {
        obj.join_date = dayjsCurrentTimestamp({ date: obj.join_date, timezone: rootState.settings.timezone })
        obj.last_update = dayjsCurrentTimestamp({ date: obj.last_update, timezone: rootState.settings.timezone })
        obj.was_online = dayjsCurrentTimestamp({ date: obj.was_online, timezone: rootState.settings.timezone })
        obj = removeEscapeCharacters(obj)
        if (localizeFields) obj = localizeCSVFields(obj, type)
        if (allowedFields) obj = filterFields(obj, allowedFields)
        return obj
      })
      commit('csvReady', 100)
      commit('downloadcsv', { data: fullData, type: type, from: from })
    },
    updateFileName: async ({ commit }, { type, from }) => {
      let name = sanitize(`${type}_${from}`)
      commit('setCSVname', name)
    },
    translateText: async ({ state, commit, rootState }, { text }) => {
      const translateTo = rootState.settings.translate
      try {
        const translatedCacheKey = JSON.stringify({
          translateTo,
          text
        })
        const hasTranslatedCache = state.translatedCache.has(translatedCacheKey)
        if (!hasTranslatedCache) {
          const { data: { translation } } = await apiRequest(`/translate`, 'POST', {}, config.servers.lang_server, {
            lang: translateTo,
            text: text
          })
          commit('setTranslatedCacheField', {key: translatedCacheKey, data: { data: { translation } } })
          return {
            data: {
              translation
            }
          }
        }
        return state.translatedCache.get(translatedCacheKey)
      } catch (error) {
        console.debug('[Translate/catch]', error)
      }
    },
    async downloadcsvMessages ({ dispatch, commit, state, rootState }, {
      query, messageLimit, allowedFields, includeRepliedMessage, from, format, type,
      localizeFields, messages, translateMessages, name
    }) {
      if (state.csv.ready > 0 && state.csv.ready < 100) return
      dispatch('clearCSVData')
      commit('csvReady', 40)
      commit('setCSVname', name)
      let fullData = []
      let localQuery = {
        ...query,
        offset: 0,
        limit: 100
      }
      let final = 10000
      let url = `/messages/`
      while (fullData.length < final) {
        const queryString = getQueryString(localQuery)
        try {
          let { data } = messages ? { data: messages } : await apiRequest(`${url}?${queryString}`, 'GET')
          for (let j = 0, len = data.length; j < len; j++) {
            let addition = {}
            if (+messageLimit === 0) {
              fullData.push(data[j])
            } else {
              let context = []
              try {
                context = await dispatch('getMessageContext', {
                  messageId: data[j].message_id,
                  chatId: data[j].chat_id,
                  limit: messageLimit
                })
                context = await addMainMessageField(context, data[j].message_id)
              } catch {
                context = [data[j].main]
              }
              fullData.push(...context)
            }
            fullData.push(flatternObj(Object.assign({}, addition, data)))
          }
          localQuery.offset += localQuery.limit
          if (!data.length || data.length === 1) break
        } catch (err) {
          console.debug(err)
          break
        }
      }
      try {
        if (includeRepliedMessage) {
          fullData = await dispatch('getMessagesReplies', fullData)
        }
        if (translateMessages) {
          fullData = await Promise.all(fullData.map(async entry => {
            const { data: { translation } } = await dispatch('translateText', { text: entry.message })
            entry.translated_message = translation
            return entry
          }))
        }
      } catch (err) {
        console.debug(err)
      }
      try {
        fullData = await dispatch('getChannelName', fullData)
      } catch (err) {
        console.debug(err)
      }
      try {
        fullData = await dispatch('getUserName', fullData)
      } catch (err) {
        console.debug(err)
      }
      fullData = filteredArr(fullData)
      fullData = fullData.map(obj => {
        obj.date = dayjsCurrentTimestamp({
          date: obj.date,
          timezone: rootState.settings.timezone
        })
        obj.message = messageMarkup(obj)
        obj = removeEscapeCharacters(obj)
        if (localizeFields) obj = localizeCSVFields(obj, type)
        if (allowedFields) obj = filterFields(obj, allowedFields)
        return obj
      })
      commit('csvReady', 100)
      commit('downloadcsv', { data: fullData, type: 'messages', from, allowedFields, format })
      commit('clearAllowedFields')
    },
    async getAuthorId ({ dispatch }, { link }) {
      const linkId = TgcpJoinLink.extractLinkId(link)
      const isUserId = TgcpJoinLink.isUserId(linkId)
      if (!isUserId) return
      const id = TgcpJoinLink.extractUserId(linkId)
      if (!id) return
      try {
        const { userdata: { answer_status } } = await dispatch('fetchUserById', { id })
        // eslint-disable-next-line camelcase
        if (answer_status !== 'FOUND') return
        return id
      } catch (error) {
        console.debug(error)
      }
    },
    fetchKnownLinks: async ({ dispatch, commit }, { chatId }) => {
      try {
        commit('clearKnownLinks')
        const { data: { links } } = await apiRequest(`/chats/${chatId}/links`, 'GET')
        for (let link of links) {
          link.authorId = await dispatch('getAuthorId', { link: link.task })
        }
        commit('setKnownLinks', links)
      } catch (error) {
        console.debug(error)
      }
    },
    clearMessages ({ commit }) {
      commit('clearMessages')
    },
    clearUsers ({ commit }) {
      commit('clearUsers')
    },
    clearChannels ({ commit }) {
      commit('clearChannels')
    },
    clearMediaMessagesToView ({ commit }) {
      commit('clearMediaMessagesToView')
    },
    clearCSVData ({ commit }) {
      commit('clearCSVData')
    },
    setcsvReady ({ commit }, percent) {
      commit('csvReady', percent)
    },
    setAutoDownload ({ commit }, autostart) {
      commit('setAutoDownload', autostart)
    }
  }
}
