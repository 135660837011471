<template>
  <div>
    <changelogRu
      v-if="locale === 'ru'"
    />
    <div
      v-else
      class="uk-card uk-margin-top uk-card-default uk-card-body uk-text-left"
      style="box-shadow: none;"
    >
      <h4>
        1.99.3
        <small>
          (2024-11-29)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.99.2
        <small>
          (2024-10-28)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Metadata information for attachments.
        </li>
        <li>
          Now previous Telegram chats/channels owners are shown correctly.
        </li>
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.99.1
        <small>
          (2024-09-16)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Support of additional special symbols in cases export
        </li>
        <li>
          Correct support for mentions in Discord module
        </li>
        <li>
          Added link to Invite link creator in Discord module
        </li>
        <li>
          Possibility to hide NSFW forums with blur
        </li>
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.99.0
        <small>
          (2024-08-30)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Discord module: Users, Channels, Tasks, Messages pages
        </li>
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.98.7
        <small>
          (2024-07-30)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.98.6
        <small>
          (2024-06-18)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.98.5
        <small>
          (2024-06-07)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Support of Telegram Chats Topics in Telegram module
        </li>
        <li>
          Forum module UX improvements
        </li>
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.98.4
        <small>
          (2024-04-29)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Followers/Following list now have search filters in Twitter Module
        </li>
        <li>
          Structured fields in forums module now shown correctly
        </li>
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.98.3
        <small>
          (2024-04-17)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.98.2
        <small>
          (2024-04-12)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.98.1
        <small>
          (2024-03-26)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.98.0
        <small>
          (2024-03-22)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Added IMO module
        </li>
        <li>
          Cases now show invite codes
        </li>
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.97.5
        <small>
          (2024-02-03)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Date filters for activity in chats and most active users have been added
        </li>
        <li>
          Added a "Resync only user data" resync option for Telegram users with usernames
        </li>
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.97.4
        <small>
          (2024-01-27)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          For Telegram users with usernames, added user data resync when resyncing user chats
        </li>
        <li>
          Now multiple owners of Telegram Chats are correctly shown
        </li>
        <li>
          New options for configuring Web Sources
        </li>
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.97.3
        <small>
          (2023-12-20)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Added Similar image search feature
        </li>
        <li>
          Removed Phone number monitoring from OSINT Tools
        </li>
        <li>
          Archived flag for global search items
        </li>
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.97.2
        <small>
          (2023-11-17)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Added flag for "Fake" chats/channels in Telegram
        </li>
        <li>
          Export improvements
        </li>
        <li>
          New Classified Boards added
        </li>
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.97.1
        <small>
          (2023-10-16)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.97.0
        <small>
          (2023-10-01)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Added Ton Blockchain Explorer
        </li>
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.96.3
        <small>
          (2023-09-30)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Added View guild action to Discord link popups
        </li>
        <li>
          Added Show User and Search by phone actions to WhatsApp link popups
        </li>
        <li>
          Adjusted max width of interface items
        </li>
        <li>
          Added support for /s/ Telegram links
        </li>
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.96.2
        <small>
          (2023-08-31)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Added Discord module (Guild information)
        </li>
        <li>
          Various Forums fixes and improvements
        </li>
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.96.1
        <small>
          (2023-08-16)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          External link for forum messages
        </li>
        <li>
          Support for Premium Status Emojis in Telegram Module
        </li>
        <li>
          Updated FAQ
        </li>
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.96.0
        <small>
          (2023-07-30)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Added topic activity charts for Forums users
        </li>
        <li>
          Added flags for "Support", "Scam", "Premium" and "Verified" users in Telegram
        </li>
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.95.1
        <small>
          (2023-06-29)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.95.0
        <small>
          (2023-06-01)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Now you can set file name when exporting data
        </li>
        <li>
          Support for Custom Animated emojis in Telegram Module
        </li>
        <li>
          Button for resyncing all chats users in
        </li>
        <li>
          Bot accounts limit now always shown correctly
        </li>
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.94.4
        <small>
          (2023-05-22)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Status page for last update status of available forums
        </li>
        <li>
          Linked chats now correctly shown in Historical Tab (Telegram Module)
        </li>
        <li>
          Image previews for .webp files in Telegram Module
        </li>
        <li>
          Optimized performance for export operations (Telegram Module)
        </li>
        <li>
          Build optimization
        </li>
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.94.3
        <small>
          (2023-04-15)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.94.2
        <small>
          (2023-03-22)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Additional filters for Telegram Tasks are added
        </li>
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.94.1
        <small>
          (2023-02-28)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Link to external resources now available for all forum topics in forum module
        </li>
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.94.0
        <small>
          (2023-02-17)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          TikTok Module
        </li>
        <li>
          Ton wallet info UX improvements (NFT, Jettons)
        </li>
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.93.1
        <small>
          (2023-01-30)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Information about Telegram usernames owned by TON Wallet
        </li>
        <li>
          Ton wallet info UX improvements
        </li>
        <li>
          Telegram Task synchronization information
        </li>
        <li>
          Search tip when searching by ID for Telegram Users and Channels
        </li>
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.93.0
        <small>
          (2022-12-30)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Added support for TON Virtual Phone Numbers
        </li>
        <li>
          Profile Export now occurs on client side
        </li>
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.92.2
        <small>
          (2022-11-24)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Labels on charts are now clickable in every applicable module
        </li>
        <li>
          Added Total MSISDN counter for parsed phones country list in Telegram module statistics tab
        </li>
        <li>
          Added tabs for Former participants of Whatsapp groups (Former Users/Admins/Superadmins)
        </li>
        <li>
          Added Parsed phones statistics section in Whatsapp module
        </li>
        <li>
          Relations Info tab for Twitter Users
        </li>
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.92.1
        <small>
          (2022-10-27)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Relations Info tab for Telegram Users
        </li>
        <li>
          Fixed error with Whatsapp media attachments in Safari
        </li>
        <li>
          Links are now correctly highlighted in Whatsapp channel descriptions
        </li>
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.92.0
        <small>
          (2022-10-07)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Fixed issue with search using some specific unicode characters in safari
        </li>
        <li>
          Added button to image menu for searching using image recognition
        </li>
        <li>
          Whatsapp tasks UX improvements
        </li>
        <li>
          Clicking on Twitter links now provides the operator with an extended menu similar to the one for Telegram joinlinks
        </li>
        <li>
          Added ability to search hoop messages on user detail page
        </li>
        <li>
          Multiple UI and UX fixes
        </li>
      </ul>
      <h4>
        1.91.3
        <small>
          (2022-09-13)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Small UI and UX improvements
        </li>
      </ul>
      <h4>
        1.91.2
        <small>
          (2022-09-07)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Private Twitter accounts now have correct marking on them
        </li>
        <li>
          Support of new Telegram links (username.t.me)
        </li>
        <li>
          Fixed bug with stickers sometimes not shown in Whatsapp Module
        </li>
        <li>
          Telegram channels when stored now sent to correct category
        </li>
        <li>
          Multiple bug and UI fixes
        </li>
      </ul>
      <h4>
        1.91.1
        <small>
          (2022-08-31)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Restricted Telegram channels now have correct marking on them
        </li>
        <li>
          Organizations removed from menu
        </li>
        <li>
          Added a face selection menu for when images with multiple faces are uploaded to the face search module
        </li>
        <li>
          Whatsapp batch tasks processing UI improvements
        </li>
        <li>
          Multiple bug fixes
        </li>
      </ul>
      <h4>
        1.91.0
        <small>
          (2022-08-12)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Channel tags are now correctly shown on Channel detail page in Hoop Module
        </li>
        <li>
          Searching across detected faces database (across partial database)
        </li>
        <li>
          Button to Discuss group has been added to Telegram channel detail page
        </li>
        <li>
          Clicking on Whatsapp invite link now provides the operator with an extended menu similar to the one for Telegram joinlinks
        </li>
        <li>
          Multiple bug fixes
        </li>
      </ul>
      <h4>
        1.90.4
        <small>
          (2022-07-30)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          "Known links" tab in Whatsapp module
        </li>
        <li>
          Some UX improvements
        </li>
        <li>
          Multiple bug fixes
        </li>
      </ul>
      <h4>
        1.90.3
        <small>
          (2022-07-24)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          "Go to message" link in Whatsapp module
        </li>
        <li>
          More detailed Whatsapp tasks statuses
        </li>
        <li>
          QoL improvements for Hoop tasks
        </li>
        <li>
          Default sorting for messages in Hoop Module is changed
        </li>
        <li>
          Changed "Search by ID" behaviour in Telegram module
        </li>
        <li>
          UI improvements for messages by Channels in discuss chats in Telegram module
        </li>
        <li>
          Auto scraping discuss chats when parsing channel if they doesn't have username in Telegram module
        </li>
        <li>
          Links to / Links by UI improvements
        </li>
        <li>
          Multiple bug and UI fixes
        </li>
      </ul>
      <h4>
        1.90.2
        <small>
          (2022-07-03)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Links to / Links by UI improvements
        </li>
        <li>
          Multiple bug and UI fixes
        </li>
      </ul>
      <h4>
        1.90.1
        <small>
          (2022-06-27)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Multiple avatars correctly shown for Whatsapp module
        </li>
        <li>
          Multiple bug and UI fixes
        </li>
      </ul>
      <h4>
        1.90.0
        <small>
          (2022-06-18)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Media crawled via Whatsapp module now correctly shown
        </li>
        <li>
          Multiple Whatsapp module UX improvements
        </li>
        <li>
          Multiple bug and UI fixes
        </li>
      </ul>
      <h4>
        1.89.4
        <small>
          (2022-06-09)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Multiple bug and UI fixes
        </li>
      </ul>
      <h4>
        1.89.3
        <small>
          (2022-06-05)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Users in Hoop module can be filtered by country now. Also country is shown for Hoop users.
        </li>
        <li>
          Ability to add Tasks for Hoop module
        </li>
        <li>
          Relations Info tab for Telegram Chats/Channels based on joinlink propagation
        </li>
        <li>
          Unified image info UI
        </li>
        <li>
          Multiple bug and UI fixes
        </li>
      </ul>
      <h4>
        1.89.2
        <small>
          (2022-05-19)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Users stats are now shown in Forum module's users list
        </li>
        <li>
          Multiple bug and UI fixes
        </li>
      </ul>
      <h4>
        1.89.1
        <small>
          (2022-04-20)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Added Top Forward sources graph for Telegram groups
        </li>
        <li>
          Multiple bug and UI fixes
        </li>
      </ul>
      <h4>
        1.89.0
        <small>
          (2022-03-31)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          It is now possible to specify the search fields to use when searching for Forums users
        </li>
        <li>
          Added display of historical data for forum users when available
        </li>
        <li>
          Added display of historical data for Twitter users when available
        </li>
        <li>
          Added an option to sort and filter Forums users by internal Forum IDs
        </li>
        <li>
          Multiple Twitter User avatars are shown when available
        </li>
        <li>
          Multiple bug and UI fixes
        </li>
      </ul>
      <h4>
        1.88.6
        <small>
          (2022-03-18)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Tweet search is available on Twitter User page now
        </li>
        <li>
          Now it is possible to specify the search fields to use when searching for Twitter users
        </li>
        <li>
          Multiple other UI Fixes
        </li>
      </ul>
      <h4>
        1.88.5
        <small>
          (2022-03-16)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Added Link to Twitter on tweets.
        </li>
        <li>
          Revoked joinlinks now correctly shown in Whatsapp tasks
        </li>
        <li>
          Multiple other UI Fixes
        </li>
      </ul>
      <h4>
        1.88.4
        <small>
          (2022-02-28)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Forums UI improvements
        </li>
        <li>
          Fixed bug with modal window incorrectly shown when clicking non external links
        </li>
        <li>
          Fixed bug with phone number incorrectly shown in Global Search for Telegram channels.
        </li>
        <li>
          Multiple other UI Fixes
        </li>
      </ul>
      <h4>
        1.88.3
        <small>
          (2022-01-31)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Forums UI improvements
        </li>
        <li>
          Twitter module performance improvement
        </li>
        <li>
          WA module performance improvement
        </li>
        <li>
          Multiple UI Fixes
        </li>
      </ul>
      <h4>
        1.88.2
        <small>
          (2022-01-16)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Fixed error when sometime forum messages wasn't shown on topic pages
        </li>
        <li>
          Forum module performance improvement
        </li>
        <li>
          Multiple UI Fixes
        </li>
      </ul>
      <h4>
        1.88.1
        <small>
          (2021-12-21)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Now Hoop messages when shown not in chat context correctly show chat links
        </li>
        <li>
          Hoop images have the same image info tools as other modules
        </li>
        <li>
          First level topics in Forums are extended automatically
        </li>
        <li>
          Multiple UI Fixes
        </li>
      </ul>
      <h4>
        1.88.0
        <small>
          (2021-12-10)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Added support for short Telegram joinlink format (t.me/+AAA...)
        </li>
      </ul>
      <h4>
        1.87.1
        <small>
          (2021-12-07)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Multiple UI Fixes
        </li>
      </ul>
      <h4>
        1.87.0
        <small>
          (2021-12-02)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          UI of Hoop channel list was redone to be closer to other modules
        </li>
        <li>
          Telegram: Ability to send task to specific bots
        </li>
      </ul>
      <h4>
        1.86.1
        <small>
          (2021-11-25)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Hoop: Alias accounts now correctly marked
        </li>
        <li>
          Multiple Bug fixes
        </li>
      </ul>
      <h4>
        1.86.0
        <small>
          (2021-11-24)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Added Hoop Messenger Module
        </li>
        <li>
          Export of Telegram messages now add link data
        </li>
        <li>
          Telegram chats/channels creation date is available.
        </li>
        <li>
          Telegram chats/channels flags - verified/scam
        </li>
        <li>
          Multiple Bug fixes
        </li>
      </ul>
      <h4>
        1.85.3
        <small>
          (2021-09-30)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Added "Last Checked Date" to Telegram User page.
        </li>
      </ul>
      <h4>
        1.85.2
        <small>
          (2021-09-13)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Multiple bug fixes
        </li>
      </ul>
      <h4>
        1.85.1
        <small>
          (2021-09-10)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Now twitter users and messages can be exported
        </li>
      </ul>
      <h4>
        1.85.0
        <small>
          (2021-08-23)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Markup for Telegram Messages is available
        </li>
        <li>
          Video download is stopping when user navigate from page, or stop playing
        </li>
        <li>
          UI improvements with Telegram Geo Features.
        </li>
        <li>
          Fixed video playback issues in Safari
        </li>
        <li>
          Fixed problems with Network Graphs
        </li>
        <li>
          Multiple other small bug fixes
        </li>
      </ul>
      <h4>
        1.84.2
        <small>
          (2021-07-21)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          New real-time queries: Hey.world (Search by nickname)
        </li>
        <li>
          Fixed error with messages and users exporting limits
        </li>
        <li>
          Forums UI improvements
        </li>
      </ul>
      <h4>
        1.84.1
        <small>
          (2021-07-07)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          MyWorld@Mail.ru lookup by phone number enabled
        </li>
        <li>
          Multiple bug fixes
        </li>
      </ul>
      <h4>
        1.84.0
        <small>
          (2021-06-30)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          UI loading speed enhancements for Telegram module
        </li>
        <li>
          Added support for Telegram animated stickers
        </li>
        <li>
          Chat/channel IDs in lists are now clickable
        </li>
        <li>
          Multiple bug fixes
        </li>
      </ul>
      <h4>
        1.83.2
        <small>
          (2021-06-18)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Added name translation for telegram users
        </li>
        <li>
          Telegram tasks page ux improvement
        </li>
        <li>
          Telegram reply graph now shows avatar images like the Case Graph
        </li>
        <li>
          Fixed error with external links in Safari
        </li>
        <li>
          Multiple other bug fixes
        </li>
      </ul>
      <h4>
        1.83.1
        <small>
          (2021-06-11)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Search hints for multiple modules
        </li>
        <li>
          Information about Telegram messages export limitations
        </li>
        <li>
          Chat/channel icon in Telegram tasks
        </li>
        <li>
          Known links tab for Telegram chats/channels showing known usernames and joinlinks
        </li>
        <li>
          Fixed error with source list not shown when doing global search by name
        </li>
        <li>
          Multiple other bug fixes
        </li>
      </ul>
      <h4>
        1.83.0
        <small>
          (2021-05-27)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Export added for Telegram scheduled search results
        </li>
        <li>
          Exporting phone numbers is now possible for Telegram users export
        </li>
        <li>
          Exporting messages translation is now possible for Telegram messages export
        </li>
        <li>
          Multiple bug fixes
        </li>
      </ul>
      <h4>
        1.82.3
        <small>
          (2021-04-24)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Resync button for global search results
        </li>
        <li>
          Multiple bug fixes
        </li>
      </ul>
      <h4>
        1.82.2
        <small>
          (2021-03-31)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          More accurate error messages
        </li>
        <li>
          New real-time queries (by phone): Botim
        </li>
        <li>
          New real-time queries (by username): Gab.com, Letterboxd.com
        </li>
        <li>
          Multiple bug fixes
        </li>
      </ul>
      <h4>
        1.82.1
        <small>
          (2021-03-16)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Fixes for Threema module (compatibility with new api)
        </li>
      </ul>
      <h4>
        1.82.0
        <small>
          (2021-02-27)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Ability to schedule Telegram chat/channel searches to improve coverage in desired areas
        </li>
        <li>
          Status page for last update statuses of available web sources.
        </li>
        <li>
          Facebook lookups page
        </li>
        <li>
          Multiple bug fixes
        </li>
      </ul>
      <h4>
        1.81.4
        <small>
          (2021-02-15)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Fixed error with time shown in incorrect timezone in some places (report edit, reports) when user's timezone differ from UTC
        </li>
        <li>
          Multiple bug fixes
        </li>
      </ul>
      <h4>
        1.81.3
        <small>
          (2021-02-12)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Added optional search history to Web Reader and Feed. Check the "Enable search autocomplete in Case Feed” box in the Settings menu to enable this feature
        </li>
        <li>
          Added optional feature to select arbitrary Telegram messages for Export. Check the “Enable messages selection for export” box in the Settings menu to enable this feature
        </li>
        <li>
          Multiple bug fixes
        </li>
      </ul>
      <h4>
        1.81.2
        <small>
          (2021-01-23)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          UI improvements for Feeds in cases
        </li>
        <li>
          Improved lightbox for image viewing
        </li>
        <li>
          New real-time queries (by username): Calendly, Minds.com
        </li>
      </ul>
      <h4>
        1.81.1
        <small>
          (2021-01-14)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Add support for new type of Telegram Joinlinks
        </li>
        <li>
          New real-time queries (by username): Mewe Group Search, Lbry.tv
        </li>
        <li>
          Check by phone for account existence in Signal
        </li>
        <li>
          Multiple bug fixes.
        </li>
      </ul>
      <h4>
        1.81.0
        <small>
          (2020-12-31)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Added fuzzy search for similar messages in Telegram
        </li>
        <li>
          New real-time queries (by username): KWork
        </li>
      </ul>
      <h4>
        1.80.0
        <small>
          (2020-12-25)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Added Geo Cluster Tasks functionality for Telegram
        </li>
        <li>
          Reply data can now be exported in CSV
        </li>
        <li>
          New real-time queries (by username): Codewars
        </li>
      </ul>
      <h4>
        1.79.6
        <small>
          (2020-12-18)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Username field add to chat export if applicable
        </li>
        <li>
          You can now turn off all token questions in settings.
        </li>
        <li>
          Human readable columns name in export
        </li>
        <li>
          Multiple bug fixes.
        </li>
      </ul>
      <h4>
        1.79.5
        <small>
          (2020-12-11)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Multiple bug fixes.
        </li>
      </ul>

      <h4>
        1.79.4
        <small>
          (2020-12-01)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          New real-time queries (by username): Teletype.in, Tinder
        </li>
        <li>
          New real-time queries (by phone): MyWorld@Mail.ru
        </li>
      </ul>
      <h4>
        1.79.3
        <small>
          (2020-11-24)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          New real-time queries (by username): OnlyFans, Kinopoisk, Steam, Faceit, Mixcloud
        </li>
        <li>
          You can now add notes to the selected case by pressing the Notes button on the bottom right of the screen (only shown when a case is selected)
        </li>
        <li>
          Notes added to profiles: you can now leave separate notes for every profile in your case
        </li>
        <li>
          Profile change history is available
        </li>
        <li>
          Multiple UI changes in profiles
        </li>
        <li>
          In the Telegram group search by keyword you now have an option add tasks only for chats/channels that haven’t been previously set to extraction (Add only new checkbox)
        </li>
        <li>
          In the Telegram group search by keyword you can now configure joinlink extraction options for all added tasks
        </li>
        <li>
          Twitter user's attributes -  URLs and location are shown.
        </li>
        <li>
          Multiple bug fixes.
        </li>
      </ul>
      <h4>
        1.79.2
        <small>
          (2020-10-21)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          New real-time queries (by username): EroProfile, Wordpress
        </li>
        <li>
          New real-time queries (by domain): Account extractor
        </li>
        <li>
          Global search by name now correctly search in Telegram
        </li>
        <li>
          Exported Telegram messages now have link to Telegram (if it is available)
        </li>
        <li>
          Multiple bug fixes.
        </li>
      </ul>
      <h4>
        1.79.1
        <small>
          (2020-10-08)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          New real-time queries (by username): Flipboard, Spotify, Angel.co, Soundcloud, ProductHunt, Fiverr
        </li>
        <li>
          UI optimization for tablets.
        </li>
        <li>
          Multiple bug fixes.
        </li>
      </ul>
      <h4>
        1.79
        <small>
          (2020-09-30)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          For Telegram Users you can now extract entities similar way as it done for Channels/Groups.now have the "Entities" section displayed on their detailed page.
        </li>
        <li>
          New real-time queries (by username): Kickstarter, Dribbble, Vimeo
        </li>
        <li>
          When updating feeds separator added to distinguish unread messages
        </li>
        <li>
          In profiles you can now select number of items per page.
        </li>
        <li>
          Improved profiles search. Now profiles can be searched by all field using full text search
        </li>
        <li>
          Menu/Top bar layout has been changed. Source Menu is no collapsible
        </li>
        <li>
          Improvements for case graphs with multiple profiles
        </li>
        <li>
          New layouts for case graphs. Extended graph view.
        </li>
        <li>
          All lists with pagination have ability to go to arbitrary page
        </li>
        <li>
          Multiple bug fixes.
        </li>
      </ul>
      <h4>
        1.78.1
        <small>
          (2020-08-30)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Twitter Geo Tasks Fixes.
        </li>
        <li>
          Tasks batch processing fixes.
        </li>
        <li>
          Web Reader in case fixes.
        </li>
        <li>
          All web sources can be previewed from sources list.
        </li>
        <li>
          Case's Network Graph Fixes.
        </li>
        <li>
          Last update date is now shown in web sources list.
        </li>
      </ul>
      <h4>
        1.78
        <small>
          (2020-08-19)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Web sources. Add a new source for parsing via ADD NEW SOURCE button or tab.
        </li>
        <li>
          Added export to case Web Reader.
        </li>
        <li>
          Twitter Geo in the Tasks tab and Geo search in Messages tab.
        </li>
        <li>
          Multiple bug fixes.
        </li>
      </ul>
      <h4>
        1.77
        <small>
          (2020-08-11)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Real-time tasks for Twitter
        </li>
        <li>
          Geo tasks for Twitter
        </li>
        <li>
          Web reader feed moved to case. Sources are set in case's stored items.
        </li>
        <li>
          Posts in webreader can be sorted by retrieval date
        </li>
        <li>
          You can now create and add information to profiles using the results of the Global Search
        </li>
        <li>
          Fixed triggers representation for Twitter/WhatsApp/Telegram
        </li>
        <li>
          Resync button is now only shown when the joinlink or username are available
        </li>
        <li>
          Added resync button to Telegram Feed
        </li>
      </ul>
      <h4>
        1.76
        <small>
          (2020-07-28)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Search with multiple keywords highlighting issues are fixed.
        </li>
        <li>
          Added list of used autocomplete tags, for message selection in Cases's Telegram Feeds.
        </li>
        <li>
          Telegram Tasks list now shows link to user who created joinlink if this data can be extracted.
        </li>
        <li>
          Triggers also works for content retrieved from twitter
        </li>
      </ul>
      <h4>
        1.75
        <small>
          (2020-07-23)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          In case Telegram feed: you can now check/uncheck all sources and perform batch operations on found messages
        </li>
        <li>
          Ads now have a separate details page for each ad
        </li>
        <li>
          You can now check the ratio of countries in chat by clicking on unwanted options to disable them
        </li>
        <li>
          Added an option to construct Advanced Triggers
        </li>
        <li>
          New real-time queries: Twitter (Search by phone number and email),Foursquare (Search by phone number and email), Instagram (Search by email), Bookmate (Search by email), GoodReads (Search by email), Strava (Search by email), SportTracks (Search by email),  Duolingo (Search by email), Adidas (Search by email), Nike (Search by email), Pinterest (Search by email),
          Tumblr (Search by email), Vivino (Search by username and email)
        </li>
      </ul>
      <h4>
        1.74
        <small>
          (2020-07-08)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Combined Telegram feed in cases for entities that are monitored
        </li>
        <li>
          Search for leaked password switched to new backend
        </li>
        <li>
          Multiple UI fixes
        </li>
      </ul>
      <h4>
        1.73
        <small>
          (2020-07-05)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          For Telegram chats with entities extracted and media extracted faces are shown
        </li>
        <li>
          Multiple login/logout/security enhancements
        </li>
        <li>
          Default sorting for WebReader changed
        </li>
      </ul>
      <h4>
        1.72
        <small>
          (2020-07-02)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          For chats with entities extracted - top used words are available
        </li>
        <li>
          Fixed auto-logout issue
        </li>
      </ul>
      <h4>
        1.71
        <small>
          (2020-07-01)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Now you can select arbirtary page when browsing Telegram messages
        </li>
        <li>
          Timestamps in data exports are now follow selected timezone from settings instead of UTC
        </li>
        <li>
          FAQ added to <router-link :to="{ name:'help'}">
            help
          </router-link> section
        </li>
        <li>
          Updated password policy
        </li>
        <li>
          Auto-logout set to 30 minutes
        </li>
        <li>
          When adding Telegram tasks you can set them for auto-extraction of all joinlinks with priority
        </li>
      </ul>
      <h4>
        1.70
        <small>
          (2020-06-29)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Infrastucture optimization
        </li>
        <li>
          Extracting keywords and Named Entities from 5 languages (en, it, fr, zh, de)
        </li>
        <li>
          "New telegram users" feature for finding new registrations for specific geo regions.
        </li>
        <li>
          New real-time queries: 9gag (Search by nickname), F3 (Search by nickname), Patreon (Search by nickname and User ID), Yandex Messenger (Search by phone number).
        </li>
        <li>
          Charts optimizations.
        </li>
        <li>
          When analyzing images, OCR is performed
        </li>
        <li>
          Added "Description" field to the phone monitoring tool.
        </li>
        <li>
          Added search keyword highlight.
        </li>
        <li>
          Wallet types and profile tags now have autocomplete.
        </li>
        <li>
          "Translate" button added to most of content
        </li>
        <li>
          Multiple bug fixes.
        </li>
      </ul>
      <h4>
        1.60
        <small>
          (2020-05-13)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Reports can be exported in PDF
        </li>
      </ul>
      <h4>
        1.59
        <small>
          (2020-05-11)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Trigger results can now be viewed in both List and Table view. You can move the columns to your preferred positions.
        </li>
        <li>
          Cases can now be merged and copied.
        </li>
        <li>
          Updated look for Twitter Geo.
        </li>
        <li>
          Added "Recheck" button to profiles.
        </li>
        <li>
          Results of Global Search can be exported
        </li>
        <li>
          New real-time queries: LeetCode, Boosty, CodeMentor
        </li>
        <li>
          Telegram: New registered users Detections Singapore and Palestine Territories
        </li>
      </ul>
      <h4>
        1.58.5
        <small>
          (2020-04-20)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          New real-time queries: Google Hangouts (phone number, email), OK (phone number)
        </li>
        <li>
          Tango Messenger realtime queries removed
        </li>
        <li>
          Trigger mechanism improvement
        </li>
      </ul>
      <h4>
        1.58.4
        <small>
          (2020-04-12)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          TGCP Tasks: Batch actions
        </li>
        <li>
          New real-time queries: MyWorld@Mail.ru (email, username), ICQ (phone number), Tango Messenger (phone number, email), Dev.to (username), HackerRank (username) , Moddb (username), TravelBlog (username)
        </li>
        <li>
          Profiler now makes suggestions based on email providers aliases and previous records
        </li>
        <li>
          New db added to real-time search: Gravatar (20M+ records), StackOverflow (4M+ records), YouNow (43M+ records)
        </li>
        <li>
          Online status monitoring performance improvements
        </li>
      </ul>
      <h4>
        1.58.3
        <small>
          (2020-03-28)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Map data is now fetched from own tile map server, for better security
        </li>
        <li>
          Globalsearch: UI changes
        </li>
      </ul>
      <h4>
        1.58.2
        <small>
          (2020-03-14)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Filter specific user's messages by chat
        </li>
        <li>
          Telegram: on users map you can see users that was near
        </li>
        <li>
          Globalsearch: Sidebar now shows more data from cards
        </li>
        <li>
          Extracted entities from chats now all shown in the same format
        </li>
      </ul>
      <h4>
        1.58.1
        <small>
          (2020-03-08)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Selects with big number of options are replaced with comboboxes
        </li>
        <li>
          Filter in Whatsapp groups list by owner's country
        </li>
        <li>
          Translation buttons added to group/chats/channels names/descriptions
        </li>
      </ul>
      <h4>
        1.58
        <small>
          (2020-03-06)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Whatsapp tasks list now supports high priority and collect media option
        </li>
        <li>
          New real-time queries: IMO (Search by phone number)
        </li>
        <li>
          Tasks support for Twitter module
        </li>
        <li>
          Added time zone selection in the user Settings menu
        </li>
        <li>
          Realtime map section in the Telegram module
        </li>
        <li>
          Added user geolocation history map
        </li>
        <li>
          Meta information for multiple whatsapp groups added
        </li>
        <li>
          Quick join button for whatsapp group
        </li>
      </ul>
      <h4>
        1.57
        <small>
          (2020-02-22)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <!-- <li>Initial implementation of new Telegram "People Nearby" function</li> -->
        <li>
          Telegram: Group search - better filtering
        </li>
        <!-- <li>Telegram: Added category filters to chats list</li> -->
        <li>
          Entities extracted from chats can now be exported
        </li>
        <li>
          New real-time queries: VK (Search by email, phone number)
        </li>
      </ul>
      <h4>
        1.56
        <small>
          (2020-02-19)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Output phone number resolve stats
        </li>
        <li>
          Telegram: Group search - Permutate option
        </li>
        <li>
          Telegram: Added type (chat/channel), country and language filters to chats list
        </li>
        <li>
          New real-time queries: Kaskus.com (Search by email, phone number and nickname)
        </li>
      </ul>
      <h4>
        1.55
        <small>
          (2020-02-16)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Renewed entities section
        </li>
        <li>
          New graph for user online status monitoring
        </li>
        <li>
          New real-time queries: Trello.com (Search by email and nickname), Tripadvisor.com (Search by nickname), Skype (Search by nickname, email, phone number)
        </li>
      </ul>
      <h4>
        1.54
        <small>
          (2020-02-12)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Entity tab in Whatsapp Groups
        </li>
        <li>
          User online status monitoring added to OSINT Tools section
        </li>
        <li>
          New real-time queries: LinkedIn.com
        </li>
      </ul>
      <h4>
        1.53
        <small>
          (2020-02-10)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Audience country chart in Telegram Module
        </li>
        <li>
          CSV Export added to Telegram Tasks Group search
        </li>
      </ul>
      <h4>
        1.52
        <small>
          (2020-02-09)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Country filters in Whatsapp Module
        </li>
        <li>
          Telegram Tasks Group search
        </li>
      </ul>
      <h4>
        1.51
        <small>
          (2020-02-06)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          User online status monitoring
        </li>
        <li>
          Audience country chart in Whatsapp Module
        </li>
      </ul>
      <h4>
        1.50.1
        <small>
          (2020-01-29)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Bug fixes
        </li>
      </ul>
      <h4>
        1.50
        <small>
          (2020-01-28)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Tasks list for Whatsapp Module
        </li>
      </ul>
      <h4>
        1.49.2
        <small>
          (2020-01-27)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Accounts tab for supported modules
        </li>
        <li>
          Active/Inactive filter for organizations DB
        </li>
      </ul>
      <h4>
        1.49.1
        <small>
          (2020-01-27)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Bug fixes for WhatsApp section
        </li>
      </ul>
      <h4>
        1.49
        <small>
          (2020-01-26)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Fixed instagram real-time queries by phone number
        </li>
        <li>
          When using global search you can see now state of each search
        </li>
        <li>
          Added WhatsApp section, you can now add task for parsing WhatsApp Group Chats in UI
        </li>
      </ul>
      <h4>
        1.48
        <small>
          (2020-01-22)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          New real-time queries: Viber
        </li>
      </ul>
      <h4>
        1.47
        <small>
          (2020-01-21)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Organization DB connected
        </li>
        <li>
          New real-time queries: zen.yandex.com
        </li>
      </ul>
      <h4>
        1.46
        <small>
          (2020-01-15)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Revamped Forums UI
        </li>
        <li>
          Telegram Resolve Phone button shown even for account that have no data except tg_id
        </li>
        <li>
          Telegram Resolve Phone Button now is shown on search card
        </li>
        <li>
          New real-time queries: Buzzfeed.com
        </li>
      </ul>
      <h4>
        1.45
        <small>
          (2020-01-10)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          New real-time queries: Gitee.com, Twitch.tv, YouNow.com, Periscope.tv, Gumroad.com, Eyeem.com, Coderwall.com
        </li>
        <li>
          Telegram reposts now correctly shown
        </li>
      </ul>
      <h4>
        1.44
        <small>
          (2020-01-08)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Twitter Hashtags sections
        </li>
        <li>
          New real-time queries: BitBucket.org, Gitlab.com
        </li>
        <li>
          Improved email detection for Github accounts
        </li>
        <li>
          Extended image information
        </li>
      </ul>
      <h4>
        1.43.1
        <small>
          (2019-12-18)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Batch processing for forensic data enrichment
        </li>
      </ul>
      <h4>
        1.43
        <small>
          (2019-12-15)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          MSISDN and IMSI to Geolocation resolver added (note: subject to availability in some countries, please refer to the supported MNO and Countries list)
        </li>
        <li>
          New real-time queries: Tiktok, Keybase.io, GetPocket.com
        </li>
      </ul>
      <h4>
        1.42
        <small>
          (2019-12-10)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Multiple software bugs fixes
        </li>
      </ul>
      <h4>
        1.41
        <small>
          (2019-12-09)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          One click button to create a profile from Telegram accounts
        </li>
      </ul>
      <h4>
        1.40.1
        <small>
          (2019-12-05)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Bug fixes
        </li>
      </ul>
      <h4>
        1.40
        <small>
          (2019-12-04)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Triggers added
        </li>
      </ul>
      <h4>
        1.39
        <small>
          (2019-12-03)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          New classified ads boards: Kiliks.com, My.adswan.com
        </li>
      </ul>
      <h4>
        1.38
        <small>
          (2019-12-02)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          News monitor module added
        </li>
        <li>
          Classified ads boards ads search added
        </li>
      </ul>
      <h4>
        1.37
        <small>
          (2019-11-29)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Telegram historical data is available
        </li>
        <li>
          Telegram: Channels and Users geo data is shown
        </li>
        <li>
          Profiles merging
        </li>
        <li>
          New classified ads boards: Casa.it, Wikicasa.it, Idealista.it, Bakeca.it, Olx.kz
        </li>
        <li>
          Leaks can be searched using regexps/wildcards
        </li>
      </ul>
      <h4>
        1.36
        <small>
          (2019-11-19)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Telegram: You can invite bots to private groups for parsing
        </li>
        <li>
          New access tools added. <router-link :to="{ name:'ip_msisdn_detect'}">
            MSISDN detection phishing tool generator
          </router-link>.
        </li>
        <li>
          Facebook MSISDN Resolve
        </li>
      </ul>
      <h4>
        1.35
        <small>
          (2019-11-18)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Automatic profiler now suggests information based on relevancy score.
        </li>
      </ul>
      <h4>
        1.34
        <small>
          (2019-11-14)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          New Real-time <router-link :to="{path:'/'}">
            Twitter Geo search
          </router-link>
        </li>
        <li>
          New classified ads boards: AutoScout24.fr,ParuVendu.fr, Ouestfrance-immo.com, LebonCoin, Annonces du bateau,Automobile.fr,Lacentrale.fr ,Auto-occasion.fr,Iimmoboulevard.com, dorus.ru, Kupiprodai.ru, Rydo.ru
        </li>
        <li>
          Leak source filter now available before search
        </li>
        <li>
          Now users will popup notification when their cases are updated
        </li>
        <li>
          Faster search in Classified Ads Boards
        </li>
        <li>
          Telegram: you can now set period of synchronization for tasks
        </li>
      </ul>
      <h4>
        1.33
        <small>
          (2019-11-07)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Network graph exporting now is correctly exporting full graph
        </li>
        <li>
          Telegram chats users activity chart
        </li>
        <li>
          New real-time queries: WhatsApp
        </li>
        <li>
          Telegram: import multiple geo tasks
        </li>
      </ul>
      <h4>
        1.32.1
        <small>
          (2019-10-29)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Bug fixes
        </li>
      </ul>
      <h4>
        1.32
        <small>
          (2019-10-29)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Darknet Forums
        </li>
        <li>
          Generation of PDF reports for profiles/cases
        </li>
        <li>
          New real-time queries: Ebay, Ask.fm
        </li>
        <li>
          Performance optimisation
        </li>
        <li>
          Telegram: Users can now be filtered by country based on their mobile number
        </li>
      </ul>
      <h4>
        1.31
        <small>
          (2019-10-19)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Fixed: Password Hash search in Leaks DB
        </li>
        <li>
          New real-time queries: Medium, Badoo, Patreon
        </li>
        <li>
          Telegram tasks can now be assigned to case
        </li>
      </ul>
      <h4>
        1.30
        <small>
          (2019-10-15)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Zalo: user profile detail page added
        </li>
        <li>
          Telegram Tasks list performance improvement
        </li>
        <li>
          Global Map performance improvements
        </li>
        <li>
          When uploding multiple Telegram tasks, you can now select to collect media in all of them
        </li>
        <li>
          Fixed issue when searching invalid phone number
        </li>
        <li>
          Now correctly handles errors from leaks api
        </li>
        <li>
          Unset default case now reset current case
        </li>
        <li>
          Wallets added to profiles
        </li>
        <li>
          Approve fixed in profiles
        </li>
        <li>
          Fix issues with Threema search
        </li>
      </ul>
      <h4>
        1.29
        <small>
          (2019-10-10)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          BTC Cells coverage scheme added on global map
        </li>
        <li>
          When setting Telegram geo-task, you see other tasks that you've placed before
        </li>
        <li>
          Fix in user list search when character '@' appears
        </li>
        <li>
          Resync button does not appear when sync date is null (syncing now state)
        </li>
        <li>
          Charts in details can now be filtered by date
        </li>
        <li>
          Option to collect media in specific tasks added
        </li>
        <li>
          Profiles filter and search improvements
        </li>
      </ul>
      <h4>
        1.28.1
        <small>
          (2019-10-08)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Change Telegram MSISDN lookup check behaviour between synced/realtime
        </li>
        <li>
          Fix for menu scroll bar
        </li>
        <li>
          Translate button now available on replies
        </li>
        <li>
          Telegram group map markers now shows information about number of users in this group
        </li>
      </ul>
      <h4>
        1.28
        <small>
          (2019-10-07)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          MSISDNs lookups for Facebook/VK/Instagram are now available in global search
        </li>
        <li>
          Telegram chats with username now have resync button available on detail page
        </li>
        <li>
          MSISDNs statistic for leaks is available now
        </li>
        <li>
          New Classified ads boards: adswan.com, us.claseek.com, id.claseek.com, sg.claseek.com,
          hk.claseek.com, ph.claseek.com, au.claseek.com, in.claseek.com, my.claseek.com
        </li>
      </ul>
      <h4>
        1.27
        <small>
          (2019-10-06)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Global map Telegram Chats clustering now depends on users count
        </li>
        <li>
          Former users/admins list now shown on Telegram Chats
        </li>
        <li>
          Chats where users have been admins now shown on Telegram Users page
        </li>
        <li>
          Tabs contents in Messenger/Social Networks sections now load on demand
        </li>
        <li>
          New Classified ads boards: subito.it, vivastreet.it, kijiji.it, immobilare.it
        </li>
        <li>
          Profiles in cases can now be tagged. Tags can be used for filtering.
        </li>
      </ul>
      <h4>
        1.26
        <small>
          (2019-10-02)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Telegram resync now activates inactive tasks
        </li>
        <li>
          Global map added
        </li>
        <li>
          Full-text search added to Twitter
        </li>
        <li>
          Translate button added to Messages and tweets
        </li>
      </ul>
      <h4>
        1.25
        <small>
          (2019-10-01)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Small internal bugs fixed
        </li>
        <li>
          Threema search now will not redirect to global search
        </li>
        <li>
          New real-time queries: Telegram, VK
        </li>
        <li>
          Profiles are now searchable in global search
        </li>
        <li>
          Profiles can be created from global search when searching by phone or email
        </li>
        <li>
          Autobinding messengers to profiles
        </li>
        <li>
          Telegram tasks can now be resynced faster
        </li>
      </ul>
      <h4>
        1.24
        <small>
          (2019-09-24)
        </small>
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          New real-time queries: Imgur, Disqus, SlideShare, Venmo
        </li>
        <li>
          Fix: Sometimes after login, cases list wasn't cleared from cache
        </li>
        <li>
          Cases now can be shared to other users
        </li>
        <li>
          Another cache issue fix
        </li>
        <li>
          If we are logged out, we should be redirected to desired page after login
        </li>
      </ul>
      <h4>
        1.23
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          New real-time queries: Tumblr, Etsy, Reddit
        </li>
        <li>
          Sometimes stored object state was shown incorrectly
        </li>
        <li>
          Leaks search by IP addresses fixed
        </li>
        <li>
          Results of search in leaks can be exported in CSV
        </li>
      </ul>
      <h4>
        1.22
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          New real-time queries: multiple address book lookups, Behance, Pinterest
        </li>
        <li>
          Profile now use first available name and image as profile name and avatar image correspondingly
        </li>
        <li>
          Telegram geo tasks selector now shows radius
        </li>
      </ul>
      <h4>
        1.21
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Cache invalidation fix
        </li>
        <li>
          Zalo lookup added
        </li>
        <li>
          Zalo user list
        </li>
        <li>
          Profile update appear without page reload
        </li>
        <li>
          Fix error with detection of Vietnamese phone numbers
        </li>
      </ul>
      <h4>
        1.20
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Changes in twitter profile graphs
        </li>
        <li>
          Detail page for twitter profile is fixed
        </li>
        <li>
          Updated real-time queries provider for twitter
        </li>
        <li>
          Threema handlers now send data to new server
        </li>
        <li>
          New real-time queries: about.me, 500px
        </li>
        <li>
          Password in leaks are shown as cards now
        </li>
        <li>
          Search fields added to all Map components
        </li>
        <li>
          UI Changes
        </li>
      </ul>
      <h4>
        1.19
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Twitter users list added
        </li>
        <li>
          All twitter handlers transferred to new Twitter API Server
        </li>
        <li>
          New real-time queries: gfycat, pornhub
        </li>
        <li>
          Fix for problems with version caching
        </li>
        <li>
          UI Changes
        </li>
      </ul>
      <h4>
        1.18
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Added separator for chats in tasks list
        </li>
        <li>
          Added show on map button for get tasks in tasks list
        </li>
        <li>
          Fix validation for some world locations
        </li>
        <li>
          UI Changes
        </li>
      </ul>
      <h4>
        1.17
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Geo task support for Telegram
        </li>
        <li>
          Changelog added to UI
        </li>
        <li>
          Fix for issue with the none redirecting to page after logout
        </li>
        <li>
          Fix node info for Twitter node in cases network graph
        </li>
        <li>
          Fix search for long nicknames
        </li>
        <li>
          Fix more/less link in cards
        </li>
        <li>
          New Classified ads boards: silutesskelbimai.lt, ntbumas.lt, dirba.lt
        </li>
        <li>
          Fix task import for Telegram
        </li>
      </ul>
      <h4>
        1.16
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Fix phone number detection for Vietnamese phone numbers
        </li>
        <li>
          Add Network graph for cases
        </li>
        <li>
          Leaks should be shown as cards in search
        </li>
        <li>
          Added leaks search
        </li>
        <li>
          Added Network Graph for cases
        </li>
        <li>
          Search for GitHub profiles by email
        </li>
        <li>
          Added facebook profiles search
        </li>
        <li>
          Threema now uses new API
        </li>
        <li>
          Added Threema statistics
        </li>
        <li>
          Add ability to add network graph element to stored objects
        </li>
        <li>
          New Classifieds Ads boards: autogidas.lt, olx.com.pa, classified-ssingapore.expats.com,
          ekontaktas.lt, gumtree.sg, rinka.lt, singaporelocanto.sg
        </li>
      </ul>
      <h4>
        1.15
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Add network graph options (BFS/Radial graphs)
        </li>
        <li>
          Now when clicking on network graph nodes, it show details in offcanvas
        </li>
        <li>
          Fix pagination in profile list
        </li>
      </ul>
      <h4>
        1.14
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          New path for manuals
        </li>
        <li>
          Fixes for Leaks statistics
        </li>
        <li>
          Fixing github provider that couldn't find some accounts
        </li>
        <li>
          Localize Ads statistics
        </li>
        <li>
          Added Livejournal profile search
        </li>
        <li>
          Correct pagination in profile list
        </li>
        <li>
          New Classified ads boards: sylhetdivision.bd, click.bd, cian.ru, domofond, khanslist
        </li>
      </ul>
      <h4>
        1.13
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Markdown support for cases description
        </li>
        <li>
          HLR button to phone numbers in cases
        </li>
      </ul>
      <h4>
        1.12
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Fixing uncheckable high priority checkbox in some browsers
        </li>
        <li>
          Added profiles to cases
        </li>
        <li>
          Added Leaks statistics
        </li>
      </ul>
      <h4>
        1.11
      </h4>
      <ul class="uk-list uk-list-bullet">
        <li>
          Long description in search cards now displayed shortened
        </li>
        <li>
          Added Flickr profile search
        </li>
        <li>
          Fixed data extraction for search cards
        </li>
        <li>
          Save user path after relogin
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import changelogRu from '@/views/Changelog-ru.vue'
export default {
  name: 'Changelog',
  components: {
    changelogRu
  },
  computed: {
    locale () {
      return this.$i18n.locale
    }
  }
}
</script>
