import Base from '../../Base.js'
import Schemas from '../../../Schemas'

class DiscordMessagesAll extends Base {
  schema = Schemas.discord.message
  OPTIONS_SCHEMA = ['subtype', 'query', 'allowedFields', 'localizeFields', 'messages', 'translateMessages', 'id']
  fetch = async ({ dispatch, options }) => {
    const { query, messages, subtype, translateMessages, allowedFields, id } = options
    if (!!messages && messages.length) {
      return messages
    } else {
      const fetchData = {
        id,
        query: { ...query, include: [] }
      }
      for (const item of ['embeds', 'attachments', 'sticker_items']) {
        if (allowedFields.findIndex(i => String(i).valueOf() === item) !== -1) fetchData.query.include.push(item)
      }

      let localQuery = {
        ...fetchData.query,
        offset: 0,
        limit: 100
      }

      switch (subtype) {
        case 'user': localQuery.author_id = fetchData.id; break
        case 'channel': localQuery.channel_id = fetchData.id; break
        case 'guild': localQuery.guild_id = fetchData.id; break
        default: break
      }

      let entries = []
      while (localQuery.offset < this.total) {
        const { data: messages } = await dispatch('fetchAllMessages', { query: localQuery, type: subtype, id: localQuery.id })
        let entry = messages
        try {
          if (translateMessages) {
            entry = await Promise.all(
              entry.map(async entry => {
                const {
                  data: { translation }
                } = await dispatch('utils/translateText', { text: entry.content })
                entry.translated_text = translation
                return entry
              })
            )
          }
        } catch (err) {
          console.debug(err)
        }
        entries = entries.concat(entry)
        localQuery.offset += localQuery.limit
        this.loaded = (entries.length / this.total) * 100
      }
      return entries
    }
  }
}

export default DiscordMessagesAll
