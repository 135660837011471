<template>
  <ul
    v-if="Object.keys(rawFiltered).length"
    :class="classComputed"
  >
    <li
      v-if="sub"
      class="sub-nav"
      @click="isOpenned = !isOpenned"
    >
      <span
        v-if="isOpenned"
        uk-icon="chevron-up"
      />
      <span
        v-else
        uk-icon="chevron-down"
      />
      <strong>
        {{ sub }}
      </strong>
    </li>
    <li
      v-for="(value, key, i) in rawFiltered"
      :key="i"
    >
      <span v-if="typeof value === 'function'"/>
      <span v-if="!isAssignedValue(value)"/>
      <span v-else-if="typeof value !== 'object'">
        <span v-if="isShownElem(value)">
          <span class="raw-key">
            {{ normalizeStr(key) }}{{ ': ' }}
          </span>
          <showMore
            class="raw-value"
            :text="value"
            :limit="150"
          />
        </span>
      </span>
      <recursiveRender
        v-else-if="isShownElem(value)"
        :raw="value"
        :sub="normalizeStr(key)"
      />
    </li>
  </ul>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
  name: 'RawDetailsRecursiveRender',
  components: {
    recursiveRender: defineAsyncComponent(() => import('@/components/providers/rawDetailsRecursiveRender')),
    showMore: defineAsyncComponent(() => import('@/components/providers/showMore'))
  },
  props: {
    sub: {
      required: false,
      type: String
    },
    raw: {
      required: true,
      type: null
    }
  },
  data () {
    return {
      isOpenned: false
    }
  },
  computed: {
    rawFiltered () {
      if (!this.raw) return false
      return Object.fromEntries(
        Object.entries(this.raw)
          .sort(([, a], [, b]) => {
            // -1  => a - first
            //  0
            //  1  => b - first
            const aIsObj = typeof a === 'object'
            const bIsObj = typeof b === 'object'
            if (aIsObj && !bIsObj) return 1
            if (!aIsObj && bIsObj) return -1
            return 0
          })
          .filter(([key, val]) => {
            if (typeof val === 'number') return true
            if (typeof val === 'string') return !!val.trim()
            if (Array.isArray(val)) return val.length
            return true
          })
      )
    },
    classComputed () {
      return this.sub ? 'uk-nav-sub sub-padding' : ''
    }
  },
  methods: {
    isAssignedValue (value) {
      if (['boolean', 'number'].includes(typeof value)) return true
      if (typeof value === 'string') return !!value.trim()
      return !!value
    },
    isShownElem (value) {
      if (this.sub) return this.isOpenned
      return true
    },
    normalizeStr (str) {
      const rex = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/gm
      const word = ('.,/?!+;' + str)
        .replace(rex, '$1$4 $2$3$5')
        .replace(/[/.,!?;\W_-]+/g, ' ')
        // eslint-disable-next-line no-useless-escape
        .replace(/[`\n~!@#$%^&*()_|+=?;:'",.<>\{\}\[\]\\\/]/gi, '')
        .trim()
        .split('')
        .filter(char => char.charCodeAt(0) > 31 && char.charCodeAt(0) <= 42655)
        .join('')
        .toLowerCase()
      const res = word[0].toUpperCase() + word.slice(1)
      return res
    }
  }
}
</script>

<style lang="scss" scoped>
.raw-key {
  font-weight: 501;
}
.raw-value {
  font-size: 0.85em;
  word-break: break-all;
}
</style>

<style lang="scss">
.sub-padding {
  padding-left: 10px !important;
}
.sub-nav {
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    transform: scale(1.02);
  }
  &:active {
    transform: scale(0.98);
  }
}
</style>
