// @ts-check
/**
 * @typedef {{ options: StrategyOptions, preset: StrategyPreset, OPTIONS_SCHEMA: OptionsSchema, schema: Schema, reject: (reason?: any) => reason, start: Start, stop: () => void, fetch: Fetch, lock: boolean, total: number, loaded: number }} Strategy
 * @typedef {{ type: string, subtype: string, namespace: string, search: string, participantType: string, participantStatus: string, localizeFields: boolean, allowedFields: string[], fileName?: string, id?: string, format?: string, for?: string, entries?: object[], totalCount: number }} StrategyOptions
 * @typedef {{ options: IndexType, optionsSchema: OptionsSchema}} Params
 * @typedef {{ dispatch: Dispatch, rootState: object, options: object }} FetchOptions
 * @typedef {(options: FetchOptions) => Promise<DispatchReturns>} Fetch
 * @typedef {(action: string, payload: object) => DispatchReturns} Dispatch
 * @typedef {string[]} Schema
 * @typedef {{ [index: string]: any }} IndexType
 * @typedef {{ format: string }} StrategyPreset
 * @typedef {Promise<any>} DispatchReturns
 * @typedef {() => DispatchReturns} Start
 * @typedef {string[]} OptionsSchema
 */

import store from '../store.js'
import Utils from '../Utils'
import contextProcessor from '../../../utils/context-processor.js'

/**
 * @class
 * @readonly
 * @implements Strategy
 */
class Base {
  preset = { format: 'csv' }
  /**
   * @type {number}
   */
  static version = 0
  /**
   * @type {Schema}
   */
  schema = []
  /**
   * @type {OptionsSchema}
   */
  OPTIONS_SCHEMA = []
  /**
   * @type {number}
   */
  loaded = 0
  /**
   * @type {number}
   */
  total = 0
  /**
   * @type {boolean}
   */
  lock = false
  /**
   * @type {(reason?: any) => reason}
  */
  reject = reason => reason
  /**
   * @readonly
   * @return {Schema}
   */
  getSchema () {
    return this.schema
  }
  /**
   * @type {Fetch}
   */
  fetch = async options => ({ data: [] })
  /**
   * @protected
   * @readonly
   * @constructor
   * @param {StrategyOptions} options
   */
  constructor (options) {
    this.description = Utils.getDescription(options)
    this.options = options
    Base.version++
    this.registerStore()
  }
  /**
   * @readonly
   */
  stop () {
    try {
      this.lock = true
      this.reject(new Error('reject'))
    } catch (error) {
      console.debug(error)
    }
  }
  /**
   * @private
   * @readonly
   * @param {Params} params
   * @return {object|void}
   */
  static getOptions (params) {
    /**
     * @type {IndexType}
     */
    let options = {}
    try {
      params.optionsSchema.forEach(option => {
        try {
          options[option] = params.options[option]
        } catch (error) {
          console.debug(error)
        }
      })
      return options
    } catch (error) {
      console.debug(error)
    }
  }
  /**
   * @private
   * @readonly
   * @param {object} payload
   * @param {string} action
   * @return {DispatchReturns}
   */
  dispatch (action, payload) {
    try {
      if (this.lock) {
        return Promise.reject(Error())
      }
      let path = ''
      const storeName = this.storeName()
      const hasPath = action.includes('/')
      if (!hasPath && storeName !== void 0) {
        path = storeName + '/'
      }
      return new Promise(async (resolve, reject) => {
        try {
          this.reject = reject
          let currentPayload = payload
          const isTranslate = action === 'utils/translateText'
          const context = contextProcessor()
          if (currentPayload.text === null) {
            currentPayload.text = ''
          }
          if (isTranslate) {
            currentPayload.text = context.decode(currentPayload.text)
          }
          let reason
          try {
            reason = await store.dispatch(path + action, currentPayload)
          } catch (error) {
            this.reject(error)
          }
          if (isTranslate) {
            reason.data.translation = context.encode(reason.data.translation)
          }
          resolve(reason)
        } catch (error) {
          console.debug(error)
        }
      })
    } catch (error) {
      console.debug(error)
    }
    return Promise.reject(new Error())
  }
  /**
   * @private
   * @readonly
   * @return {string|null}
   */
  storeName () {
    try {
      return 'export' + Utils.nameNormalize(this.options.namespace) + Base.version.toString()
    } catch (error) {
      console.debug(error)
      return null
    }
  }
  /**
   * @private
   * @readonly
   * @return {Promise<void>}
   */
  async registerStore () {
    try {
      const storeName = this.storeName()
      const module = require('../../../store/modules/' + this.options.namespace).default
      if (storeName) {
        store.registerModule(storeName, module, { preserveState: false })
      }
    } catch (error) {
      console.debug(error)
    }
  }
  /**
   * @readonly
   * @return {Promise<DispatchReturns>}
   */
  async start () {
    try {
      this.lock = false
      this.total = this.options?.totalCount ? (this.options.totalCount > 5000 ? 5000 : this.options.totalCount) : 0
      const preset = {
        options: this.options,
        optionsSchema: this.OPTIONS_SCHEMA.concat(['for'])
      }
      const options = Base.getOptions(preset)
      if (options !== void 0) {
        Object.assign(Symbol, { isExport: true })
        let entries = await this.fetch({
          dispatch: this.dispatch.bind(this),
          rootState: store.state,
          options: {
            ...options,
            allowedFields: this.options.allowedFields
          }
        })
        entries = Utils.currentValues({
          entries,
          schema: this.schema
        })
        entries = Utils.convertTypes(entries)
        Object.assign(Symbol, { isExport: false })
        return entries
      }
    } catch (error) {
      console.debug(error)
    }
    return Promise.reject(Error())
  }
}

export default Base
