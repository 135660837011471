import Base from '../../Base.js'
import Schemas from '../../../Schemas'
import i18n from '../../../../../i18n.js'

class TgcpUsersAll extends Base {
  schema = Schemas.tgcp.user
  OPTIONS_SCHEMA = ['type', 'query', 'allowedFields', 'localizeFields', 'resolvePhoneNumbers']
  STATS_FIELDS = ['admin_count', 'all_admin_count', 'all_chats_count', 'all_owner_count', 'chats_count', 'first_geo_seen', 'first_message_date', 'former_admin_count', 'former_chats_count', 'former_owner_count', 'last_geo_seen', 'last_message_date', 'media_messages_count', 'messages_count', 'owner_count']
    fetch = async ({ dispatch, options }) => {
    const { type, query, users, resolvePhoneNumbers, allowedFields } = options
    const statsFlag = allowedFields.some(r => this.STATS_FIELDS.includes(r))
    if (!!users && users.length) {
      return users
    } else {
      let localQuery = {
        ...query,
        offset: 0,
        limit: 100,
        withoutFetchingToView: true
      }
      let entries = []
      let entry = Array(1)
      let continueFlag = true
      while (entry.length && localQuery.offset < (localQuery.chat_id ? 5000 : 1000) && continueFlag) {
        const data = await dispatch('fetchAllUsers', localQuery)
        entry = [...data]
        if (entry.length < 100) continueFlag = false
        if (entry.length > 0 && entry[0].user) {
          entry = entry.map(item => item.user)
        }
        try {
          if (statsFlag) {
            for (const index in entry) {
              try {
                const data = await dispatch('getStats', {
                  id: entry[index].tg_id,
                  type
                })
                entry[index] = {
                  ...entry[index],
                  ...data
                }
              } catch (error) {
                console.debug(error)
              }
            }
          }
          if (resolvePhoneNumbers) {
            for (const index in entry) {
              try {
                const { data } = await dispatch('resolvePhoneNumber', { id: entry[index].tg_id })
                entry[index].phone_number = data.phone
              } catch (error) {
                if (
                  error.hasOwnProperty('response') &&
                  error.response.status === 403 &&
                  error.response.hasOwnProperty('data') &&
                  error.response.data.hasOwnProperty('code') &&
                  error.response.data.code === 'NOT_ENOUGH_FUNDS'
                ) {
                  entry[index].phone_number = i18n.global.t('balance.non_enough_funds_error')
                }
                console.debug(error)
              }
            }
          }
        } catch (error) {
          console.debug(error)
        }
        entries = entries.concat(entry)
        localQuery.offset += localQuery.limit
      }
      return entries
    }
  }
}

export default TgcpUsersAll